/* eslint-disable no-console */
/* eslint-disable import/prefer-default-export */
import _ from 'lodash';
import { colors, createTheme, responsiveFontSizes } from '@mui/material';
import typography from './typography';
import { strongShadows } from './shadows';
import { THEMES } from '../constants';

const baseConfig = {
  direction: 'ltr',
  typography,
  overrides: {
    MuiLinearProgress: {
      root: {
        borderRadius: 3,
        overflow: 'hidden',
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: 32,
      },
    },
    MuiChip: {
      root: {
        backgroundColor: 'rgba(0,0,0,0.075)',
      },
    },
  },
};

export function createCustomTheme(settings = {}) {
  const themeConfig = {
    name: THEMES.LIGHT,
    overrides: {
      MuiInputBase: {
        input: {
          '&::placeholder': {
            opacity: 1,
            color: colors.blueGrey[600],
          },
        },
      },
    },
    palette: {
      mode: 'light',
      action: {
        active: colors.blueGrey[600],
      },
      background: {
        default: colors.common.white,
        paper: colors.common.white,
      },
      primary: {
        main: '#22335E',
        darker: 'hsl(223 47% 18% / 1)',
      },
      secondary: {
        main: '#FCC561',
        darker: 'hsl(39 96% 60% / 1)',
      },
      text: {
        primary: colors.blueGrey[900],
        secondary: colors.blueGrey[600],
        tertiary: 'white',
      },
    },
    logo: {
      filter: 'brightness(0) invert(100%)',
      filterLoad: 'invert(100%)',
    },
    settings: {
      filter: '',
    },
    shadows: strongShadows,
  };

  let theme = createTheme(
    (_.merge(
      {},
      baseConfig,
      themeConfig,
      { direction: settings.direction },
    )),
  );

  if (settings.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
}
