import React from 'react';
import { Container } from '@mui/material';
import Page from '../../../../components/Layout/Page';
import Header from './Header';
import UserCreateForm from './UserCreateForm';

import useStyles from './styles';

function UserCreateView() {
  const classes = useStyles();

  return (
    <Page
      className={classes.root}
      // TODO: Actualizar título
      title="BSS Abogados | Agregar Usuario"
    >
      <Container maxWidth={false}>
        <Header />
        <UserCreateForm />
      </Container>
    </Page>
  );
}

export default UserCreateView;
