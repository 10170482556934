import * as XLSX from 'xlsx';
import moment from 'moment';

const s2ab = (s) => {
  const buf = new ArrayBuffer(s.length);
  const view = new Uint8Array(buf);
  // eslint-disable-next-line no-bitwise
  for (let i = 0; i < s.length; i += 1) view[i] = s.charCodeAt(i) & 0xff;
  return buf;
};

const downloadExcel = (data, name) => {
  const ws = XLSX.utils.json_to_sheet(data);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Hoja1');
  const wbout = XLSX.write(wb, { type: 'binary', bookType: 'xlsx' });
  const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' });
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  const date = moment().format();
  link.download = `${name}-${date}.xlsx`;
  link.click();
};

export default downloadExcel;
