import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  MenuItem,
  Autocomplete,
} from '@mui/material';

import { useSelector, useDispatch } from 'react-redux';

import { editUserData } from '../../../../requests/api/users';

import handleApiResponse from '../../../../utils/http';
import useStyles from './styles';
import { getAllClients } from '../../../../services/casesService';
import { setUserData } from '../../../../actions/accountActions';

function UserEditForm({
  className,
  user,
  stores,
  ...rest
}) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [clients, setClients] = useState([]);
  const [clientsSelected, setClientsSelected] = useState([]);
  const [updatedUser, setUpdatedUser] = useState(null);
  const userAdmin = useSelector((state) => state.account.user);
  // TODO: Remove this when the super-admin role is ready
  const notSuperAdmin = userAdmin.email !== 'ariel@tubesoft.cl' && userAdmin.email !== 'ssanchez@bsslegal.cl';

  useEffect(() => {
    if (updatedUser) {
      dispatch(setUserData(updatedUser));
    }
  }, [updatedUser]);

  useEffect(() => {
    const getClients = async () => {
      const response = await getAllClients(userAdmin.clients.map((c) => c.idPersona));
      setClients(response.clients);
    };
    getClients();
  }, []);

  useEffect(() => {
    if (user && user.clients) {
      setClientsSelected(
        user.clients
          .map((c) => ({ label: `${c.nombres} ${c.paterno} ${c.materno}`, id: c.idPersona })),
      );
    }
  }, [user]);

  if (submitSuccess) {
    window.location.reload(true);
  }

  return (
    <Formik
      initialValues={{
        name: user.name || '',
        lastname: user.lastname || '',
        role: user.isAdmin ? 'admin' : 'user',
        email: user.email || '',
        active: user.active || false,
        password: '',
        confirmPassword: '',
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().max(255).required('Debe ingresar un nombre'),
        lastname: Yup.string().max(255).required('Debe ingresar un apellido'),
        email: Yup.string().email('Email inválido').required('Debe ingresar email'),
        active: Yup.boolean().required('Debe seleccionar un estado'),
        password: Yup.string()
          .max(255)
          .notRequired()
          .test(
            'checkEmpty',
            'Debe ingresar una contraseña',
            (value) => {
              if (value && value.length > 0) {
                return true;
              } if (!value || value.length === 0) {
                return true;
              }
              return false;
            },
          ),
        confirmPassword: Yup.string()
          .max(255)
          .when('password', {
            is: (password) => !!password && password.length > 0,
            then: Yup.string()
              .oneOf([Yup.ref('password'), null], 'Las contraseñas deben coincidir')
              .required('Debe confirmar su contraseña'),
            otherwise: Yup.string().notRequired(),
          }),
      })}
      onSubmit={async (values, {
        setSubmitting,
      }) => {
        try {
          const data = {
            name: values.name,
            lastname: values.lastname,
            role: values.role,
            active: values.active,
            password: values.password,
            clients: clientsSelected.map((c) => c.id),
          };
          const response = await editUserData(user.id, data);
          setUpdatedUser(response.user);
          handleApiResponse(enqueueSnackbar, response, true);
          setSubmitSuccess(true);
          setSubmitting(false);
        } catch (error) {
          handleApiResponse(enqueueSnackbar, error, false);
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form
          className={clsx(classes.root, className)}
          onSubmit={handleSubmit}
          {...rest}
        >

          <Card>
            <CardContent>
              <Grid
                container
                spacing={3}
              >
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    error={Boolean(touched.name && errors.name)}
                    fullWidth
                    helperText={touched.name && errors.name}
                    label="Nombre"
                    name="name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.name}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    error={Boolean(touched.lastname && errors.lastname)}
                    fullWidth
                    helperText={touched.lastname && errors.lastname}
                    label="Apellido"
                    name="lastname"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.lastname}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label="Email"
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.email}
                    variant="outlined"
                    disabled
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    disabled={notSuperAdmin}
                    fullWidth
                    label="Rol"
                    name="role"
                    onChange={handleChange}
                    select
                    value={values.role}
                    variant="outlined"
                  >
                    <MenuItem
                      value="admin"
                    >
                      ADMIN
                    </MenuItem>
                    <MenuItem
                      value="user"
                    >
                      USER
                    </MenuItem>
                  </TextField>
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    error={Boolean(touched.password && errors.password)}
                    fullWidth
                    helperText={touched.password && errors.password}
                    label="Password"
                    name="password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.password}
                    variant="outlined"
                    type={'password'}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(touched.confirmPassword && errors.confirmPassword)}
                    fullWidth
                    helperText={touched.confirmPassword && errors.confirmPassword}
                    label="Confirmar Password"
                    name="confirmPassword"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.confirmPassword}
                    variant="outlined"
                    type={'password'}
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label="Estado"
                    name="active"
                    onChange={handleChange}
                    select
                    value={values.active}
                    variant="outlined"
                  >
                    <MenuItem
                      value={true}
                    >
                      ACTIVO
                    </MenuItem>
                    <MenuItem
                      value={false}
                    >
                      INACTIVO
                    </MenuItem>
                  </TextField>
                </Grid>
                <Grid item
                  md={6}
                  xs={12} >
                  <Autocomplete
                    disabled={user.id === userAdmin.id && notSuperAdmin}
                    disableCloseOnSelect
                    multiple
                    id="clients-autocomplete"
                    options={clients.map((c) => ({ label: `${c.nombres} ${c.paterno} ${c.materno}`, id: c.idPersona }))}
                    getOptionLabel={(option) => option.label}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    filterSelectedOptions
                    value={clientsSelected}
                    onChange={(e, v) => {
                      setClientsSelected(v);
                    }}
                    limitTags={10}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Clientes"
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Box mt={2}>
                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Guardar
                </Button>
              </Box>
            </CardContent>
          </Card>
        </form>
      )}
    </Formik>
  );
}

UserEditForm.propTypes = {
  className: PropTypes.object,
  user: PropTypes.object.isRequired,
  stores: PropTypes.array,
};

export default UserEditForm;
