import React from 'react';
import PropTypes from 'prop-types';

import { SvgIcon } from '@mui/material';
import { useHistory } from 'react-router-dom';

import {
  PlusCircle as PlusCircleIcon,
} from 'react-feather';

import useStyles from './styles';
import CustomHeader from '../../../../components/Layout/ CustomHeader';
import HeaderButton from '../../../../components/Layout/HeaderButton';

function Header({ className, ...rest }) {
  const classes = useStyles();
  const history = useHistory();

  const icon = () => (<SvgIcon
    fontSize="small"
    className={classes.actionIcon}
  >
    <PlusCircleIcon />
  </SvgIcon>);

  return (
    <CustomHeader
      classes={classes}
      className={className}
      title="Usuarios"
      {...rest}
    >
      <HeaderButton
          icon={icon()}
          onClick={() => {
            history.push('/administracion/users/crear');
          }}
          text="Agregar usuario"
        />
    </CustomHeader>
  );
}

Header.propTypes = {
  className: PropTypes.object,
};

export default Header;
