import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

import {
  Card, CardContent, CardHeader, IconButton,
} from '@mui/material';
import { Close } from '@mui/icons-material';

const Involved = ({
  classes, open, setOpen, data,
}) => {
  const handleClose = () => setOpen(false);
  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Card className={classes.floatingCard}>
          <CardHeader
            className={classes.cardHeader}
            action={
              <IconButton onClick={() => setOpen(false)}>
                <Close />
              </IconButton>
            }
            title={
              <Typography variant="h4">INVOLUCRADOS</Typography>
            }>
          </CardHeader>
          <CardContent className={classes.cardContent}>
            <div className={classes.textTitle}>Involucrado</div>
            <div className={classes.textBody}>{data.nombre}</div>
            <div className={classes.textTitle}>Calidad</div>
            <div className={classes.textBody}>{data.calidad}</div>
            <div className={classes.textTitle}>Observaciones</div>
            <div className={classes.textBody}>{data.observaciones}</div>
          </CardContent>
        </Card>
      </Modal>
    </>
  );
};

Involved.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  classes: PropTypes.object,
  data: PropTypes.object,
};

export default Involved;
