import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import moment from 'moment';
import {
  Box,
  Button,
  Card,
  Checkbox,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import { format } from 'rut.js';

import useStyles from './styles';
import { PERSONA_JURIDICA } from '../../../utils/const';

function Results({
  className,
  rows,
  count,
  page,
  setPage,
  limit,
  setLimit,
  deleteClients,
  ...rest
}) {
  const classes = useStyles();
  const [selectedRows, setSelectedRows] = useState([]);
  const [currentRows, setCurrentRows] = useState(rows);

  useEffect(() => { setCurrentRows(rows); }, [rows]);

  const handleSelectAllRows = (event) => {
    setSelectedRows(event.target.checked
      ? currentRows.map((row) => row.idConsulta)
      : []);
  };

  const handleSelectOneRow = (event, rowId) => {
    if (!selectedRows.includes(rowId)) {
      setSelectedRows((prevSelected) => [...prevSelected, rowId]);
    } else {
      setSelectedRows((prevSelected) => prevSelected.filter((id) => id !== rowId));
    }
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const enableBulkOperations = selectedRows.length > 0;
  const selectedSomeRows = selectedRows.length > 0 && selectedRows.length < currentRows.length;
  const selectedAllRows = selectedRows.length === currentRows.length;

  const handleDeleteRow = async () => {
    const { success } = await deleteClients(selectedRows);
    if (success) {
      setCurrentRows(currentRows.filter((e) => (!selectedRows.includes(e.idConsulta))));
      setSelectedRows([]);
    }
  };

  const formatName = (row) => {
    if (row.tipoPersona === PERSONA_JURIDICA) {
      return row.nombre;
    }
    return `${row.nombre || ''} ${row.paterno || ''} ${row.materno || ''}`;
  };

  const formatRut = (row) => format(`${row.rutConsult}${row.dvConsult}`);

  return (
      <Card
        className={clsx(classes.rootResults, className)}
        {...rest}
      >
        <Divider />
        <Box
          p={0}
          minHeight={0}
          display="flex"
          alignItems="center"
        >
        </Box>
        {enableBulkOperations && (
          <div className={classes.bulkOperations}>
            <div className={classes.bulkActions}>
              <Checkbox
                style={{ color: 'white' }}
                checked={selectedAllRows}
                indeterminate={selectedSomeRows}
                onChange={handleSelectAllRows}
              />
              <Button
                variant="outlined"
                className={classes.bulkAction}
                onClick={() => handleDeleteRow()}
              >
                Borrar
              </Button>
            </div>
          </div>
        )}
        <PerfectScrollbar>
          <Box minWidth={700}>
            <Table>
              <TableHead className={classes.tableHead}>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedAllRows}
                      indeterminate={selectedSomeRows}
                      onChange={handleSelectAllRows}
                    />
                  </TableCell>
                  <TableCell className={classes.tableHeadCell}>
                    Nombre
                  </TableCell>
                  <TableCell className={classes.tableHeadCell}>
                    Rut a consultar
                  </TableCell>
                  <TableCell className={classes.tableHeadCell}>
                    Fecha Ingreso
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {currentRows.map((row) => {
                  const isRowSelected = selectedRows.includes(row.idConsulta);

                  return (
                    <TableRow
                      hover
                      key={row.idConsulta}
                      selected={isRowSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isRowSelected}
                          onChange={(event) => handleSelectOneRow(event, row.idConsulta)}
                          value={isRowSelected}
                        />
                      </TableCell>
                      <TableCell>
                        {formatName(row)}
                      </TableCell>
                      <TableCell>
                        {formatRut(row)}
                      </TableCell>
                      <TableCell>
                        {moment(row.fecIngreso).format('DD/MM/YYYY')}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
        <TablePagination
          component="div"
          count={count}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 25]}
          labelRowsPerPage="Filas por página"
        />
      </Card>
  );
}

Results.propTypes = {
  className: PropTypes.object,
  limit: PropTypes.number.isRequired,
  setLimit: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  rows: PropTypes.array,
  count: PropTypes.number.isRequired,
  deleteClients: PropTypes.func.isRequired,
};

Results.defaultProps = {
  rows: [],
};

export default Results;
