import { ArrowDropDown, ArrowRight } from '@mui/icons-material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

const ButtonDropDown = ({ onClick }) => {
  const [open, setOpen] = useState(false);
  return (
    <div
        onClick={() => {
          setOpen(!open);
          onClick();
        }}
    >
        { open ? <ArrowDropDown fontSize="large" sx={{ color: 'black' }}/>
          : <ArrowRight fontSize="large" sx={{ color: 'black' }} /> }
    </div>);
};

ButtonDropDown.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default ButtonDropDown;
