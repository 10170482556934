import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import SplashScreen from '../Loading/SplashScreen';
import AuthService from '../../services/authService';
import { rehydration } from '../../store';

const {
  logout,
  setUserData,
} = require('../../actions/accountActions');

function Auth({ children }) {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(true);
  const [rehydrationComplete, setRehydrationComplete] = useState(false);
  const { user: reduxUser = {} } = useSelector((state) => state?.account);

  useEffect(() => {
    const getRehydration = async () => {
      await rehydration();
      setRehydrationComplete(true);
    };
    getRehydration();
  }, []);

  useEffect(async () => {
    if (rehydrationComplete) {
      const initAuth = async () => {
        AuthService.setAxiosInterceptors({ onLogout: () => dispatch(logout()) });

        AuthService.handleAuthentication();

        if (AuthService.isAuthenticated()) {
          const user = await AuthService.loginInWithToken();
          if (user) {
            if (reduxUser?.client?.idPersona) {
              const updatedClient = user.clients?.find(
                (c) => c.idPersona === reduxUser.client?.idPersona,
              );
              user.client = updatedClient || null;
            } else {
              user.client = user.clients.length ? user.clients[0] : null;
            }
            dispatch(setUserData(user));
          } else {
            dispatch(logout());
          }
        }

        setLoading(false);
      };

      initAuth();
    }
  }, [rehydrationComplete]);

  if (isLoading) {
    return <SplashScreen />;
  }

  return children;
}

Auth.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Auth;
