import React from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  TextField,
  FormHelperText,
  Link,
  Grid,
  // Checkbox,
  // Link,
} from '@mui/material';

import { useSnackbar } from 'notistack';

import handleApiResponse from '../../utils/http';
import authService from '../../services/authService';
import useStyles from './styles';
import Page from '../../components/Layout/Page';
import CustomHeader from '../../components/Layout/ CustomHeader';

function RecoveryForm() {
  const classes = useStyles();
  const { recoverPassword } = authService;
  const { enqueueSnackbar } = useSnackbar();
  const [sent, setSent] = React.useState(false);

  const handleOnSubmit = async (values, { setSubmitting }) => {
    try {
      const response = await recoverPassword(values.email);
      setSubmitting(false);
      if (response.success) {
        setSent(true);
        handleApiResponse(enqueueSnackbar, response, true);
      }
    } catch (error) {
      handleApiResponse(enqueueSnackbar, error, false);
      setSubmitting(false);
    }
  };

  return (
    <Page
      className={classes.root}
      title="Login"
    >
      <Grid container justify="center" className={classes.grid}>
        <Grid item lg={7} md={7} sm={12} xs={12} >
          <div className={classes.loginImage}>
            <img
              alt="loginImage"
              src="/img/logo_bss_white.png"
              className={classes.logoImage}
            />
          </div>
        </Grid>
        <Grid item lg={5} md={5} sm={12} xs={12}>
          <CustomHeader
            classes={classes}
            className={classes}
            title="Recuperar contraseña"
          ></CustomHeader>

          <Formik
            initialValues={{
              email: '',
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string().required('Email requerido'),
            })}
            onSubmit={handleOnSubmit}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <form
                noValidate
                onSubmit={handleSubmit}
                className={classes.recoveryForm}
              >
                <TextField
                  InputProps={{
                    style: {
                      borderWidth: '1px',
                      borderStyle: 'solid',
                      borderColor: touched.email && errors.email ? 'transparent' : 'black',
                      borderRadius: 0,
                      background: '#f2f2f2',
                    },
                  }}
                  error={Boolean(touched.email && errors.email)}
                  color="secondary"
                  fullWidth
                  autoFocus
                  helperText={touched.email && errors.email}
                  placeholder="Email"
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.email}
                  variant="outlined"
                />
                <Box sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  p: 1,
                  m: 1,
                  bgcolor: 'background.paper',
                  borderRadius: 1,
                }}>
                  <Link className={classes.link}
                    href="/ingreso" color="inherit">Iniciar sesión</Link>
                </Box>
                <Box mt={2}>
                  <Button
                    className={classes.button}
                    disabled={isSubmitting || sent}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    { sent ? 'Correo enviado' : 'Enviar correo' }
                  </Button>
                  {errors.submit && (
                    <Box mt={3}>
                      <FormHelperText error>
                        {errors.submit}
                      </FormHelperText>
                    </Box>
                  )}
                </Box>
              </form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </Page>
  );
}

RecoveryForm.defaultProps = {
  onSubmitSuccess: () => { },
};

export default RecoveryForm;
