import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {
  Card, CardContent, CardHeader, IconButton, TableBody,
} from '@mui/material';
import { Close } from '@mui/icons-material';

const History = ({
  classes, open, setOpen, data,
}) => {
  const handleClose = () => setOpen(false);
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Card className={classes.floatingCard}>
          <CardHeader
            className={classes.cardHeader}
            action={
              <IconButton onClick={() => setOpen(false)}>
                <Close />
              </IconButton>
            }
            title={
              <Typography variant="h4">HISTORIAL DE TRAMITACIÓN</Typography>
            }>
          </CardHeader>
          <CardContent className={classes.cardNoPaddingContent}>
            <TableContainer style={{ maxHeight: '40vh', overflow: 'auto' }}>
              <Table size="small" aria-label="customized table">
                <TableHead>
                  <TableRow className={classes.tableRow}>
                    <TableCell align="center" className={classes.historyTableCellHeader}>Estado</TableCell>
                    <TableCell align="center" className={classes.historyTableCellHeader}>Fecha</TableCell>
                    <TableCell align="center" className={classes.historyTableCellHeader}>Comentario</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data?.acciones?.map((row, i) => (
                    <TableRow key={i} className={classes.tableRow}>
                      <TableCell align="center" className={classes.historyTableCell}>{row.estado}</TableCell>
                      <TableCell align="center" className={classes.historyTableCell}>{moment(row.fecTermino).format('DD-MM-YYYY')}</TableCell>
                      <TableCell align="center" className={classes.historyTableCell}>{row.observaciones}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </Card>
      </Modal>
    </div>
  );
};

History.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  classes: PropTypes.object,
  data: PropTypes.object,
};

export default History;
