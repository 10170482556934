import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  searchIcon: {
    backgroundColor: '#222A35',
    color: '#fff',
    borderRadius: '0 0 0 0',
    height: '50px',
    width: '50px',
    '&:hover': {
      background: '#FCC561',
    },
  },
  searchBox: {
    border: '2px solid #222A35',
    display: 'flex',
    alignItems: 'center',
    width: 230,
    height: '50px',
    boxShadow: '0 0 1px 0 rgba(0,0,0,0.70), 0 2px 2px -2px rgba(0,0,0,0.50)',
    margin: '1px',
  },
  searchBoxText: {
    paddingLeft: '10px',
    flex: 1,
  },
  searchBoxFull: {
    borderTop: '2px solid #222A35',
    borderLeft: '2px solid #222A35',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: '50px',
  },
}));

export default useStyles;
