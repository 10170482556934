import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  Container,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import Page from '../../../components/Layout/Page';
import Header from './Header';
import Results from './Results';
import useStyles from './styles';
import handleApiResponse from '../../../utils/http';
import { deleteBankruptNotifications, getBankruptsNotifications, postBankruptsNotificationAddEmail } from '../../../requests/api/bankrupts';

function ListView() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const account = useSelector((state) => state.account);

  const [refresh, setRefresh] = useState(0);
  const [rows, setRows] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [query, setQuery] = useState({});
  const [sort, setSort] = useState('email|desc');
  const [filters, setFilters] = useState({ sort });

  const [newEmail, setNewEmail] = useState({ email: '' });

  useEffect(() => {
    const getRows = async () => {
      try {
        const response = await getBankruptsNotifications({
          rutCliente: account?.user?.client?.rut || null,
          dvCliente: account?.user?.client?.dv || null,
          page,
          limit,
          query: query.text,
          sort: filters.sort,
        });
        if (response.data?.rows) {
          setRows(response.data.rows);
          setCount(response.data.count);
        }
      } catch (error) {
        handleApiResponse(enqueueSnackbar, error, false);
      }
    };
    getRows();
  }, [
    sort,
    query,
    page,
    limit,
    account,
    refresh,
    filters,
  ]);

  const addNewEmail = async () => {
    try {
      await postBankruptsNotificationAddEmail({
        newEmail: {
          rutCliente: account?.user?.client?.rut || null,
          dvCliente: account?.user?.client?.dv || null,
          ...newEmail,
        },
      });
      enqueueSnackbar('Se ha agregado el nuevo email', {
        variant: 'success',
      });
      setRefresh((prev) => prev + 1);
      return { success: true };
    } catch (error) {
      handleApiResponse(enqueueSnackbar, error, false);
      return { success: false };
    }
  };

  const deleteEmails = async (emails) => {
    if (!account.user.isAdmin) {
      enqueueSnackbar('Debe ser administrador!', {
        variant: 'error',
      });
      return { success: false };
    }

    try {
      const response = await deleteBankruptNotifications(emails);
      handleApiResponse(enqueueSnackbar, response, true);
      setRefresh((prev) => prev + 1);
      return { success: true };
    } catch (error) {
      handleApiResponse(enqueueSnackbar, error, false);
      return { success: false };
    }
  };

  return (
    <Page
      className={classes.root}
      title="BSS Abogados | Boletín BSS"
    >
      <Container maxWidth={false}>
        <Header
          setFilters={setFilters}
          setQuery={setQuery}
          sort={sort}
          setSort={setSort}
          newEmail={newEmail}
          setNewEmail={setNewEmail}
          addNewEmail={addNewEmail}
        />
        <Box mt={2}>
          <Results
            rows={rows}
            count={count}
            page={page}
            setPage={setPage}
            limit={limit}
            setLimit={setLimit}
            deleteEmails={deleteEmails}
          />
        </Box>
      </Container>
    </Page>
  );
}

export default ListView;
