import React from 'react';
import { Redirect, useHistory } from 'react-router';
import {
  Box,
  Typography,
  Grid,
} from '@mui/material';
import Page from '../../components/Layout/Page';
// import Logo from '../../components/Layout/Logo';
import LoginForm from './LoginForm';
import AuthService from '../../services/authService';
import CustomHeader from '../../components/Layout/ CustomHeader';

import useStyles from './styles';

function LoginView() {
  const classes = useStyles();
  const history = useHistory();

  if (AuthService.isAuthenticated()) {
    return <Redirect to="/causas" />;
  }

  const handleSubmitSuccess = () => {
    history.push('/causas');
  };

  return (
    <Page
      className={classes.root}
      title="Login"
    >
      <Grid container justify="center" className={classes.grid}>
        <Grid item lg={7} md={7} sm={12} xs={12} >
          <div className={classes.loginImage}>
            <img
              alt="loginImage"
              src="/img/logo_bss_white.png"
              className={classes.logoImage}
            />
          </div>
       </Grid>
        <Grid item lg={5} md={5} sm={12} xs={12}>
          <CustomHeader
            classes={classes}
            className={classes}
            title="Ingresa a tu cuenta"
          ></CustomHeader>
          <Box className={classes.content} textAlign="center">
            <Typography
              variant="subtitle1"
              color="#222A35"
            >
              <strong>Inicia sesión</strong> para utilizar la plataforma
            </Typography>
            <LoginForm className={classes} onSubmitSuccess={handleSubmitSuccess} />
          </Box>
        </Grid>
      </Grid>
    </Page>
  );
}

export default LoginView;
