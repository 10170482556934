import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box, Button, Checkbox,
  CircularProgress, FormControlLabel, MenuItem, Select, SvgIcon, TextField, Typography,
} from '@mui/material';
import { Download, Filter } from 'react-feather';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import moment from 'moment';
import { useSnackbar } from 'notistack';

import useStyles from './styles';
import CustomHeader from '../../components/Layout/ CustomHeader';
import HeaderButton from '../../components/Layout/HeaderButton';
import FloatingWindow from '../../components/Custom/FloatWindow';
import { getAllBillings } from '../../services/billingService';
import downloadExcel from '../../utils/xlsx';

function Header({
  cases, defendants, className, filters, setFilters, user, ...rest
}) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [open, setOpen] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [startAmount, setStartAmount] = useState(0);
  const [endAmount, setEndAmount] = useState(0);
  const [selectedCase, setSelectedCase] = useState('');
  const [selectedDefendant, setSelectedDefendant] = useState('');
  const [income, setIncome] = useState(false);
  const [outcome, setOutcome] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const filterIcon = () => (<SvgIcon
    fontSize="small"
    className={classes.actionIcon}
  >
    <Filter />
  </SvgIcon>);

  const downloadIcon = () => {
    if (isLoading) return (<CircularProgress sx={{ color: 'white' }} size={22} />);
    return (<SvgIcon
      fontSize="small"
      className={classes.actionIcon}
    >
       <Download />
    </SvgIcon>);
  };

  const converToCSV = async () => {
    try {
      setIsLoading(true);
      const { billings } = await getAllBillings({
        clientId: user.client.idPersona,
        start: filters.startDate ? moment(filters.startDate).format('YYYY-MM-DD') : '',
        end: filters.endDate ? moment(filters.endDate).format('YYYY-MM-DD') : '',
        demandedId: filters.selectedDefendant,
        caseId: filters.selectedCase,
        startAmount: filters.startAmount,
        endAmount: filters.endAmount,
        isIncome: filters.isIncome,
        isOutcome: filters.isOutcome,
        limit: 100000,
        page: 1,
      });

      downloadExcel(billings.map((b) => ({
        causa: b.causa,
        contraparte: b.contraparte,
        descripcion: b.descripcion,
        fecha: b.fecha,
        movimiento: b.movimiento,
        rut: b.rut,
        saldo: b.saldo,
      })), 'gastos');
    } catch {
      enqueueSnackbar('Error al obtener el csv de gastos', {
        variant: 'error',
      });
    }
    setIsLoading(false);
  };

  const openFilters = () => setOpen(!open);

  const closeFilters = () => setOpen(false);

  return (
    <CustomHeader
      classes={classes}
      className={className}
      title="Gastos"
      {...rest}
    >
      <HeaderButton
        icon={filterIcon()}
        text="Filtrar Gastos"
        onClick={openFilters}
      />
      <HeaderButton
        icon={downloadIcon()}
        text="Descargar Gastos"
        onClick={converToCSV}
      />
      <FloatingWindow
        open={open}
        setOpen={setOpen}
        transform='translate(-52% , 54%)'
      >
        <Box className={{ ...classes.boxFilter, minWidth: '500px' }}>
          <Typography className={classes.filterTitle} variant="subtitle1">Rango de fechas</Typography>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Box sx={{ alignItems: 'center' }} className={classes.boxRow}>
              <DesktopDatePicker
                className={classes.datePickerLeft}
                label="Fecha inicio"
                inputFormat="DD/MM/YYYY"
                format="dd/MM/YYYY"
                value={startDate}
                onChange={(date) => {
                  setStartDate(date?.toDate());
                }}
                renderInput={(params) => <TextField {...params} />}
              />
              <div> - </div>
              <DesktopDatePicker
                className={classes.datePickerRight}
                label="Fecha termino"
                inputFormat="DD/MM/YYYY"
                format="dd/MM/YYYY"
                value={endDate}
                onChange={(date) => {
                  setEndDate(date?.toDate());
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </Box>
          </LocalizationProvider>
          <Typography className={classes.filterTitle} variant="subtitle1">Rango de monto</Typography>
          <Box sx={{ alignItems: 'center' }} className={classes.boxRow}>
            <TextField
              className={classes.amountLeft}
              id="numeric-input"
              type="number"
              inputProps={{ min: 0, max: 10000000, step: 1 }}
              InputLabelProps={{ shrink: true }}
              value={startAmount}
              onChange={(event) => setStartAmount(Number(event.target.value))}
            /> -
            <TextField
              className={classes.amountRight}
              id="numeric-input"
              type="number"
              inputProps={{ min: 0, max: 10000000, step: 1 }}
              InputLabelProps={{ shrink: true }}
              value={endAmount}
              onChange={(event) => setEndAmount(Number(event.target.value))}
            />
          </Box>
          <Typography className={classes.filterTitle} variant="subtitle1">Contraparte</Typography>
          <Select value={selectedDefendant || ''} className={classes.filterSelect}
            onChange={(event) => setSelectedDefendant(event?.target?.value || {})}>
            {defendants.map((defendant, i) => (
              <MenuItem key={i} value={defendant?.idPersona || 0}>
                {`${defendant?.nombres || 'Sin nombre'} ${defendant?.paterno || ''} ${defendant?.materno || ''}`}
              </MenuItem>
            ))}
          </Select>
          <Typography className={classes.filterTitle} variant="subtitle1">Causa</Typography>
          <Select value={selectedCase || ''}
            onChange={(event) => setSelectedCase(event?.target?.value || {})}
            className={classes.filterSelect}>
            {cases.map((c, i) => (
              <MenuItem key={i} value={c?.idCausa || 0}>
                {c.rol}
              </MenuItem>
            ))}
          </Select>
          <Box sx={{ alignItems: 'center', mb: 2 }} className={classes.boxRow}>
          <FormControlLabel className={classes.checkBoxLeft} control={
            <Checkbox
              checked={income}
              onChange={(event) => setIncome(event?.target?.checked || false)}
              color="primary"
              inputProps={{ 'aria-label': 'Abonos' }}
            />
          }
            label="Abono">
          </FormControlLabel>
          <FormControlLabel className={classes.checkBoxRigth} control={
            <Checkbox
              checked={outcome}
              onChange={(event) => setOutcome(event?.target?.checked || false)}
              color="primary"
              inputProps={{ 'aria-label': 'Gastos' }}
            />
          }
            label="Gasto">
          </FormControlLabel>
          </Box>
          <Box className={classes.buttonBox}>
            <Button className={classes.button}
              onClick={() => {
                setFilters({
                  startDate: '',
                  endDate: '',
                  setSelectedDefendant: '',
                  setSelectedCase: '',
                  startAmount: '',
                  endAmount: '',
                  isIncome: false,
                  isOutcome: false,
                });
                setStartDate(null);
                setEndDate(null);
                setSelectedDefendant('');
                setSelectedCase('');
                setStartAmount(0);
                setEndAmount(0);
                setIncome(false);
                setOutcome(false);
                closeFilters();
              }}>
              DESHACER FILTROS
            </Button>
            <Button className={classes.yellowButton}
              onClick={() => {
                setFilters({
                  startDate,
                  endDate,
                  startAmount,
                  endAmount,
                  selectedDefendant,
                  selectedCase,
                  isIncome: income,
                  isOutcome: outcome,
                });
                closeFilters();
              }}>
              APLICAR FILTROS
            </Button>
          </Box>
        </Box>
      </FloatingWindow>
    </CustomHeader>
  );
}

Header.propTypes = {
  className: PropTypes.object,
  filters: PropTypes.object.isRequired,
  setFilters: PropTypes.func.isRequired,
  cases: PropTypes.array.isRequired,
  defendants: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
};

export default Header;
