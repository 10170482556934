import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const styles = {
  errorboundaryPage: {
    height: '100vh',
    width: '100vw',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#222a35',
    margin: '0 auto',
  },
  errorboundaryLogo: {
    width: '300px',
  },
  errorboundaryTitle: {
    margin: '30px',
    padding: '20px',
    width: '100%',
    maxWidth: '650px',
    textAlign: 'center',
    fontWeight: '500',
    fontFamily: 'Arial',
    fontSize: '28px',
  },
  errorboundaryButton: {
    fontSize: '20px',
    padding: '20px',
    backgroundColor: '#fcc561',
    cursor: 'pointer',
    fontWeight: '500',
    border: '0',
  },
};

const ErrorBoundary = ({ children }) => {
  const [hasError, setHasError] = useState(false);

  const resetData = () => {
    // Clear all local storage
    localStorage.clear();

    // Expire all site cookies
    const cookies = document.cookie.split(';');
    cookies.forEach((cookie) => {
      const cookieParts = cookie.split('=');
      const cookieName = cookieParts[0].trim();
      document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
    });

    // Redirect to login
    window.location.assign('/');
  };

  useEffect(() => {
    const handleError = (/* error, errorInfo */) => {
      setHasError(true);
    };

    // Listen for errors during rendering
    window.addEventListener('error', handleError);

    return () => {
      // Remove the error event listener when the component unmounts
      window.removeEventListener('error', handleError);
    };
  }, []);

  if (hasError) {
    // Render your custom error page here
    return (
      <div style={styles.errorboundaryPage}>
        <img
          style={styles.errorboundaryLogo}
          src="https://clientes.bsslegal.cl/img/logo_bss.png"
          alt="logo"
        />
        <h1 style={styles.errorboundaryTitle}>
          Estamos teniendo dificultades en estos momentos.
          Por favor vuelve a intentar en unos minutos mas.
        </h1>
        <button
          type="button"
          style={styles.errorboundaryButton}
          onClick={resetData}
        >
          Refrescar
        </button>
      </div>
    );
  }

  return <>{children}</>;
};

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ErrorBoundary;
