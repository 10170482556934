/* eslint-disable react/display-name */
import React, { Fragment, Suspense } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import AuthGuard from './components/Auth/AuthGuard';
import DashboardLayout from './components/Navigation';
import LoadingScreen from './components/Loading/LoadingScreen';

import Login from './views/Login';
import Verification from './views/Verification';
import PasswordRecovery from './views/PasswordRecovery';

import UserListView from './views/Admin/Users/UserListView';
import UserCreateView from './views/Admin/Users/UserCreateView';
import UserEditView from './views/Admin/Users/UserEditView';
import CausasListView from './views/Cases';
import BillingsListView from './views/Billing';
import BankruptClientListView from './views/Bankrupt/ClientsListView';
import BankruptNotificationListView from './views/Bankrupt/NotificationsListView';
import RecoveryForm from './views/Login/Recovery';

const routesConfig = [
  {
    id: 1,
    exact: true,
    path: '/',
    component: () => <Redirect to="/causas" />,
  },
  {
    id: 2,
    exact: true,
    path: '/ingreso',
    component: () => <Login />,
  },
  {
    id: 3,
    exact: true,
    path: '/user-verification/:token',
    component: () => <Verification />,
  },
  {
    id: 4,
    exact: true,
    path: '/reset-password/:token',
    component: () => <PasswordRecovery />,
  },
  {
    id: 18,
    exact: true,
    path: '/recover-password',
    component: () => <RecoveryForm />,
  },
  {
    id: 5,
    exact: true,
    path: '/reset-password?token=:token',
    component: () => <Redirect to="/reset-password/:token" />,
  },
  {
    path: '/administracion',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        id: 11,
        exact: true,
        path: '/administracion',
        component: () => <Redirect to="/administracion/users" />,
      },
      {
        id: 12,
        exact: true,
        path: '/administracion/users',
        component: () => <UserListView />,
      },
      {
        id: 13,
        exact: true,
        path: '/administracion/users/crear',
        component: () => <UserCreateView />,
      },
      {
        id: 14,
        exact: true,
        path: '/administracion/users/:id/editar',
        component: () => <UserEditView />,
      },
      {
        id: 15,
        component: () => <Redirect to="/administracion/users" />,
      },
    ],
  },
  {
    id: 16,
    exact: true,
    path: '/causas',
    guard: AuthGuard,
    layout: DashboardLayout,
    component: () => <CausasListView />,
  },
  {
    id: 17,
    exact: true,
    path: '/gastos',
    guard: AuthGuard,
    layout: DashboardLayout,
    component: () => <BillingsListView />,
  },
  {
    id: 19,
    exact: true,
    path: '/quiebras/clientes',
    guard: AuthGuard,
    layout: DashboardLayout,
    component: () => <BankruptClientListView />,
  },
  {
    id: 20,
    exact: true,
    path: '/quiebras/notificaciones',
    guard: AuthGuard,
    layout: DashboardLayout,
    component: () => <BankruptNotificationListView />,
  },
];

const renderRoutes = (routes) => (routes ? (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>
                  {route.routes ? (
                    renderRoutes(route.routes)
                  ) : (
                    <Component {...props} />
                  )}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
) : null);

function Routes() {
  return renderRoutes(routesConfig);
}

export default Routes;
