import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router';
import {
  SvgIcon,
  Box,
  Tabs,
  Tab,
  TextField,
  Button,
  Grid,
} from '@mui/material';
import {
  PlusCircle as PlusCircleIcon,
} from 'react-feather';

import CustomHeader from '../../../components/Layout/ CustomHeader';
import HeaderButton from '../../../components/Layout/HeaderButton';
import { CLIENT_VIEW, NOTIFICATIONS_VIEW } from '../../../utils/const';
import SearchBox from '../../../components/Custom/SearchBox';
import Popup from '../../../components/Custom/Popup';
import useStyles from './styles';

function Header({
  setView,
  className,
  setFilters,
  setQuery,
  sort,
  setSort,
  newEmail,
  setNewEmail,
  addNewEmail,
  ...rest
}) {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  const { pathname } = location || {};

  const [openAddEmail, setOpenAddEmail] = useState(false);
  const [addClientLoading, setAddClientLoading] = useState(false);

  const plusIcon = () => (<SvgIcon
    fontSize="small"
    className={classes.actionIcon}
  >
    <PlusCircleIcon />
  </SvgIcon>);

  const handleNewEmailChange = (event) => {
    const { name, value } = event.target;
    setNewEmail((prev) => ({ ...prev, [name]: value }));
  };

  const handelCancelNewClient = () => {
    setNewEmail({ email: '' });
    setOpenAddEmail(false);
  };
  const handelCreateNewClient = async () => {
    setAddClientLoading(true);
    const { success } = await addNewEmail();
    setAddClientLoading(false);
    if (success) {
      setOpenAddEmail(false);
      setNewEmail({ email: '' });
    }
  };

  return (
    <>
      <CustomHeader
        classes={classes}
        className={className}
        title="Boletín BSS"
        {...rest}
      >
        <Tabs value={pathname}>
          <Tab
            sx={{
              width: '360px',
              backgroundColor: pathname === CLIENT_VIEW ? '#FCC561' : '#222A35',
            }}
            variant="fullWidth"
            value={CLIENT_VIEW}
            label="Clientes"
            onClick={() => {
              history.push('/quiebras/clientes');
            }}
          />
          <Tab
            sx={{
              width: '400px',
              backgroundColor: pathname === NOTIFICATIONS_VIEW ? '#FCC561' : '#222A35',
            }}
            variant="fullWidth"
            value={NOTIFICATIONS_VIEW}
            label="Notificaciones"
            onClick={() => {
              history.push('/quiebras/notificaciones');
            }}
          />
        </Tabs>
      </CustomHeader >
      <Box
        className={classes.subButtons}
      >
        <SearchBox
          setSearch={setQuery}
        />
        <HeaderButton
          icon={plusIcon()}
          onClick={() => setOpenAddEmail(true)}
          text="Agregar email"
        />
      </Box>
      <Popup
        open={openAddEmail}
        onClose={() => setOpenAddEmail(false)}
        title="AGREGAR EMAIL"
        maxWidth="700px"
      >
        <Grid container>
          <Grid item xs={12}>
            <Grid container padding="20px">
              <Grid item xs={12} textAlign="center">
                <TextField
                  className={classes.modalTextField}
                  onChange={handleNewEmailChange}
                  label="Email"
                  placeholder="Email"
                  name="email"
                  value={newEmail.email}
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12} md={6}>
                <Button
                  className={classes.modalCancelButton}
                  onClick={handelCancelNewClient}
                  disabled={addClientLoading}
                >
                  CANCELAR
                </Button>
              </Grid>
              <Grid item xs={12} md={6}>
                <Button
                  className={classes.modalAddButton}
                  onClick={handelCreateNewClient}
                  disabled={addClientLoading}
                >
                  AGREGAR
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Popup>
    </>
  );
}

Header.propTypes = {
  setView: PropTypes.func,
  className: PropTypes.object,
  setFilters: PropTypes.func,
  sort: PropTypes.string,
  setSort: PropTypes.func,
  setQuery: PropTypes.func,
  newEmail: PropTypes.object,
  setNewEmail: PropTypes.func,
  addNewEmail: PropTypes.func,
};

export default Header;
