import axios from '../../utils/axios';

const getBillings = async ({
  clientId, page, limit, start, end, demandedId, caseId, isIncome,
  isOutcome, startAmount, endAmount,
}) => {
  let query = '';
  if (clientId) query += `clientId=${clientId}&`;
  if (start) query += `start=${start}&`;
  if (end) query += `end=${end}&`;
  if (limit) query += `limit=${limit}&`;
  if (page) query += `page=${page}&`;
  if (demandedId) query += `demandedId=${demandedId}&`;
  if (caseId) query += `caseId=${caseId}&`;
  if (isIncome) query += `isIncome=${isIncome}&`;
  if (isOutcome) query += `isOutcome=${isOutcome}&`;
  if (startAmount) query += `startAmount=${startAmount}&`;
  if (endAmount) query += `endAmount=${endAmount}&`;
  const { data } = await axios.get(`/api/billings?${query}`);
  return data;
};

const getBillingsById = () => {};

export {
  getBillings,
  getBillingsById,
};
