import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  rootHeader: {
    paddingRight: theme.spacing(3),
  },
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingRight: '30px',
    width: '100%',
  },
  detailsButton: {
    backgroundColor: '#d9d9d9',
    minWidth: '20px',
    color: '#000000',
    '&:hover': {
      background: '#a9a9a9',
    },
    padding: '0px',
    minHeight: '100%',
    overflow: 'visible',
  },
  stack: {
    height: '100%',
  },
  boxRow: {
    display: 'flex',
    flexDirection: 'row',
    minHeight: '100%',
  },
  boxCol: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100%',
    minWidth: '30%',
  },
  container: {
    marginLeft: theme.spacing(3),
    minHeight: '100%',
  },
  detailsBox: {
    minHeight: '100%',
    height: '100%',
  },
  boxFilter: {
    display: 'flex',
    flexDirection: 'column',
    border: '2px solid #d9d9d9',
    maxWidth: '400px',
  },
  boxDates: {
    maxWidth: '100%',
    marginBottom: '3%',
  },
  filterSelect: {
    borderRadius: 0,
    marginLeft: '5%',
    marginRight: '5%',
    marginBottom: '3%',
    maxHeight: '45px',
    minWidth: '90%',
  },
  datePickerLeft: {
    marginLeft: '5%',
    marginRight: '1%',
  },
  datePickerRight: {
    marginLeft: '1%',
    marginRight: '5%',
  },
  filterTitle: {
    marginLeft: '5%',
    marginTop: '2%',
    marginBottom: '2%',
  },
  detailsContent: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    minHeight: '720px',
    minWidth: '320px',
  },
  table: {
    minWidth: 650,
    minHeight: '100%',
  },
  cardHistoryHeader: {
    backgroundColor: '#FCC561',
  },
  cardNoPaddingContent: {
    padding: '0px',
  },
  tableRow: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    fontSize: 10,
    fontWeight: 'bold',
    '&:first-of-type': {
      borderLeft: 'none',
    },
    '&:last-child': {
      borderRight: 'none',
    },
    height: '60px',
  },
  tableRowDetails: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    fontSize: 10,
    fontWeight: 'bold',
    '&:first-of-type': {
      borderLeft: 'none',
    },
    '&:last-child': {
      borderRight: 'none',
    },
    height: '40px',
  },
  tableRowHeader: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    fontSize: 10,
    fontWeight: 'bold',
    '&:first-of-type': {
      borderLeft: 'none',
    },
    '&:last-child': {
      borderRight: 'none',
    },
    minHeight: '30px',
  },
  tableRowHeaderDark: {
    backgroundColor: '#222A35',
    fontSize: 10,
    fontWeight: 'bold',
    '&:first-of-type': {
      borderLeft: 'none',
    },
    '&:last-child': {
      borderRight: 'none',
    },
    minHeight: '30px',
  },
  tableCellHeader: {
    padding: '0px 8px',
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    borderLeft: '2px solid #FCC561',
    borderRight: '2px solid #FCC561',
    fontSize: 10,
    fontWeight: 'bold',
    width: '33%',
    '&:first-of-type': {
      borderLeft: 'none',
    },
    '&:last-child': {
      borderRight: 'none',
    },
  },
  tableCellHeaderDark: {
    padding: '0px 8px',
    color: theme.palette.common.white,
    borderLeft: '2px solid #FCC561',
    borderRight: '2px solid #FCC561',
    fontSize: 10,
    fontWeight: 'bold',
    width: '33%',
    '&:first-of-type': {
      borderLeft: 'none',
    },
    '&:last-child': {
      borderRight: 'none',
    },
  },
  tableCell: {
    fontSize: 10,
    padding: '4px 8px',
    lineHeight: '1.14',
    borderLeft: '2px solid #FCC561',
    borderRight: '2px solid #FCC561',
    width: '33%',
    '&:first-of-type': {
      borderLeft: '#E0E0E0',
    },
    '&:last-child': {
      borderRight: '#E0E0E0',
    },
  },
  tableCellSelected: {
    backgroundColor: '#FCC561',
    lineHeight: '1.14',
    fontSize: 10,
    padding: '4px 8px',
    borderLeft: '2px solid #000000',
    borderRight: '2px solid #000000',
    width: '33%',
    '&:first-of-type': {
      borderLeft: 'none',
    },
    '&:last-child': {
      borderRight: 'none',
    },
  },
  floatingCard: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#fff',
    height: '400px',
    width: '600px',
  },
  cardHeader: {
    backgroundColor: '#FCC561',
  },
  cardPaddingContent: {
    padding: '30px',
  },
  textTitle: {
    fontSize: 12,
    fontWeight: 'bold',
    fontFamily: 'Helvetica',
  },
  textBody: {
    fontSize: 12,
    marginBottom: '20px',
    fontFamily: 'Helvetica',
  },
  button: {
    minWidth: '250px',
    minHeight: '50px',
    backgroundColor: '#222A35',
    color: '#FFF',
    '&:hover': {
      background: '#a9a9a9',
    },
    borderRadius: 0,
  },
  yellowButton: {
    minWidth: '250px',
    minHeight: '50px',
    borderRadius: 0,
    backgroundColor: '#FCC561',
    color: '#222A35',
    '&:hover': {
      background: '#a9a9a9',
    },
  },
  doubleButton: {
    minWidth: '500px',
    minHeight: '50px',
    backgroundColor: '#222A35',
    color: '#FFF',
    '&:hover': {
      background: '#a9a9a9',
    },
    borderRadius: 0,
  },
  buttonBox: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 'auto',
  },
  detailsTitle: {
    backgroundColor: '#FCC561',
    paddingLeft: '40px',
    paddingRight: '40px',
    paddingTop: '12px',
    paddingBottom: '12px',
  },
  detailsTableContainer: {
    height: '100%',
    minHeight: '215px',
    maxHeight: '20vh',
  },
  tableContainer: {
    minHeight: '100%',
    marginTop: '10px',
    paddingRight: '10px',
    borderRadius: 2,
    overflowX: 'auto',
  },
  historyTableCellHeader: {
    fontSize: 12,
    fontWeight: 'bold',
    width: '33%',
  },
  spacing: {
    marginLeft: '35px',
  },
  icon: {
    marginRight: '5px',
  },
  spinnerContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: -100,
  },
  caseBlockedRow: {
    width: '100%',
  },
  gridContainer: {
    borderBottom: '1px solid #ccc',
    padding: theme.spacing(1),
    display: 'flex',
    justifyContent: 'space-between',
    '& p': {
      fontSize: '14',
    },
    '& span': {
      fontSize: '14',
      fontWeight: 'bold',
    },
  },
}));

export default useStyles;
