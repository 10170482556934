import axios from '../../utils/axios';

const getUserDetail = async (id) => {
  const { data } = await axios.get(`/api/users/${id}`);
  return data;
};

const editUserData = async (id, newData) => {
  const { data } = await axios.put(`/api/users/${id}`, newData);
  return data;
};

const deleteUsers = async (userIds) => {
  const { data } = await axios.delete(`/api/users/${userIds}`);
  return data;
};

export {
  getUserDetail,
  editUserData,
  deleteUsers,
};
