import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Divider,
  Typography,
} from '@mui/material';

import useStyles from './styles';

const CustomHeader = ({
  title, children,
}) => {
  const classes = useStyles();
  return (
    <>
    <div className={classes.headerBss}>
      <Box sx={{
        backgroundColor: '#FCC561', pl: 5, pr: 5, pt: 1.5, pb: 1.5,
      }}>
        <Typography
          variant="h3"
          color="textPrimary"
        >
          { title.toUpperCase() }
        </Typography>
      </Box>
      <Box style={{ display: 'flex', alignItems: 'center' }}>
        {children}
      </Box>
    </div>
    <Divider sx={{ borderBottomWidth: 3, borderColor: '#FCC561' }} />
    </>
  );
};

CustomHeader.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
};

export default CustomHeader;
