import React, {
  useState,
  useEffect,
} from 'react';
import {
  CircularProgress,
  Container,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import moment from 'moment';

import Page from '../../components/Layout/Page';
import Header from './Header';

import useStyles from './styles';
import Result from './Result';
import { getAllBillings } from '../../services/billingService';

const BillingsListView = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useSelector((state) => state.account);

  const [billings, setBillings] = useState([]);
  const [billingsCount, setBillingsCount] = useState(0);
  const [amounts, setAmounts] = useState({
    total: 0,
    totalIn: 0,
    totalOut: 0,
    balance: 0,
  });

  const [page, setPage] = useState(1);
  const [rowsPerPage] = useState(10);
  const [cases, setCases] = useState([]);
  const [defendants, setDefendants] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [filters, setFilters] = useState({
    startDate: null,
    endDate: null,
  });

  useEffect(() => {
    const getData = async () => {
      try {
        setIsLoading(true);
        const data = await getAllBillings({
          clientId: user.client.idPersona,
          start: filters.startDate ? moment(filters.startDate).format('YYYY-MM-DD') : null,
          end: filters.endDate ? moment(filters.endDate).format('YYYY-MM-DD') : null,
          demandedId: filters.selectedDefendant,
          caseId: filters.selectedCase,
          startAmount: filters.startAmount,
          endAmount: filters.endAmount,
          isIncome: filters.isIncome,
          isOutcome: filters.isOutcome,
          limit: 10,
          page,
        });
        setCases(data.cases || []);
        setDefendants(data.defendants || []);
        setBillings(data.billings || []);
        setBillingsCount(data.count || 0);
        setAmounts({
          balance: data.totalBalance,
          totalIn: data.totalIncome,
          totalOut: data.totalOutcome,
          current: data.currentAmount,
        });
      } catch (err) {
        enqueueSnackbar('Error al obtener las causas', {
          variant: 'error',
        });
      }
      setIsLoading(false);
    };

    if (user?.client) {
      getData();
    }
  }, [user, page, filters]);

  useEffect(() => {
    setPage(1);
  }, [filters]);

  return (
    <>
      <Page
        className={classes.root}
        title="BSS Abogados | Gastos"
      >
          <Container
            maxWidth={false}
            className={classes.container}
          >
            <Header
              filters={filters}
              setFilters={setFilters}
              cases={cases}
              defendants={defendants}
              user={user}
            />
            { isLoading ? (<div className={classes.spinnerContainer}>
              <CircularProgress />
            </div>) : (<Result
              billings={billings}
              classes={classes}
              page={page}
              setPage={setPage}
              rowsPerPage={rowsPerPage}
              billingsCount={billingsCount}
              amounts={amounts}
            />)}
          </Container>
      </Page>
    </>
  );
};

export default BillingsListView;
