/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useLocation, matchPath } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Drawer,
  Grid,
  Hidden,
  IconButton,
  Link,
  List,
  ListSubheader,
  Modal,
  Typography,
} from '@mui/material';

import {
  AssignmentInd,
  AttachMoney,
  Close,
  Person,
  AccountBalance,
} from '@mui/icons-material';

import Logo from '../../Layout/Logo';
import NavItem from './NavItem';
import useStyles from './styles';
import { setUserData } from '../../../actions/accountActions';

const navConfig = [
  {
    items: [
      {
        title: 'Causas',
        icon: AssignmentInd,
        href: '/causas',
        isAdmin: false,
      },
      {
        title: 'Gastos',
        icon: AttachMoney,
        href: '/gastos',
        isAdmin: false,
      },
      {
        title: 'Usuarios',
        icon: Person,
        href: '/administracion/admins',
        isAdmin: true,
      },
      {
        title: 'Boletín BSS',
        icon: AccountBalance,
        href: '/quiebras',
        isAdmin: false,
        items: [
          {
            title: 'Clientes',
            icon: null,
            href: '/quiebras/clientes',
            isAdmin: false,
          },
          {
            title: 'Notificaciones',
            icon: null,
            href: '/quiebras/notificaciones',
            isAdmin: false,
          },
        ],
      },
    ],
  },
];

function renderNavItems({ items, ...rest }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, ...rest }),
        [],
      )}
    </List>
  );
}

function reduceChildRoutes({
  acc, pathname, item, depth = 0,
}) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        key={key}
        info={item.info}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
        })}
      </NavItem>,
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        key={key}
        info={item.info}
        title={item.title}
      />,
    );
  }

  return acc;
}

function NavBar({ openMobile, onMobileClose }) {
  const classes = useStyles();
  const location = useLocation();
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const account = useSelector((state) => state.account);
  const user = useSelector((state) => state.account.user);
  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line
  }, [location.pathname]);
  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box sx={{ pt: 10 }} display="flex" justifyContent="center">
            <RouterLink to="/">
              <Logo isWhite={true} className={classes.logo} />
            </RouterLink>
          </Box>
        </Hidden>
        <Box p={2} alignContent="center">
          <Box display="flex" justifyContent="center">
            <RouterLink to="/">
              <Avatar alt="User" className={classes.avatar} />
            </RouterLink>
          </Box>
          <Box mt={2} textAlign="center">
            <Link
              component={RouterLink}
              to="/"
              variant="h4"
              color="white"
              underline="none"
            >{account.user?.client && account.user?.client?.nombres}</Link>
            <Grid container>
              <Grid item xs={3} />
              <Grid item xs={6}>
                <Divider sx={{ borderBottomWidth: 3, m: 1 }} color="#FCC561" />
              </Grid>
              <Grid item xs={3} />
            </Grid>
            <Box className={classes.clientsChangeText}
              onClick={() => setOpen(true)}>
                <Typography variant="body2" color="white">
                  <span className={classes.changeText} >Cambiar cliente</span>
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box pt={2}>
          {navConfig.map((config, i) => (
            <List
              key={i}
              subheader={(
                <ListSubheader disableGutters disableSticky>
                  {config.subheader}
                </ListSubheader>
              )}
            >
              {renderNavItems({
                items: config.items.filter((item) => {
                  if (item?.isAdmin) {
                    if (user?.isAdmin) return true;
                    return false;
                  }
                  return true;
                }),
                pathname: location.pathname,
              })}
            </List>
          ))}
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return <>
    <Hidden lgUp>
      <Drawer
        anchor="left"
        classes={{ paper: classes.mobileDrawer }}
        onClose={onMobileClose}
        open={openMobile}
        variant="temporary"
      >
        {content}
      </Drawer>
    </Hidden>
    <Hidden lgDown>
      <Drawer
        anchor="left"
        classes={{ paper: classes.desktopDrawer }}
        open
        variant="persistent"
      >
        {content}
      </Drawer>
    </Hidden>
    <>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Card className={classes.floatingCard}>
          <CardHeader
            className={classes.cardHeader}
            action={
              <IconButton onClick={() => setOpen(false)}>
                <Close />
              </IconButton>
            }
            title={
              <Typography variant="h5">CAMBIAR CLIENTE</Typography>
            }>
          </CardHeader>
          <CardContent className={classes.cardNoPaddingContent}>
            <Grid container>
              {user?.clients?.map((client, i) => (
                <Grid item key={i} xs={4}>
                  <Avatar />
                  <Box sx={{ cursor: 'pointer' }}
                    onClick={async () => {
                      const newUser = { ...user, client };
                      await dispatch(setUserData(newUser));
                      setOpen(false);
                    }}>
                    <div><Typography variant="body2">{
                      // eslint-disable-next-line prefer-template
                      client.nombres
                    }</Typography></div>
                    <div><Typography variant="body2">{
                      // eslint-disable-next-line prefer-template
                      client.paterno + ' ' + client.materno
                    }</Typography></div>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </CardContent>
        </Card>
      </Modal>
    </>
  </>;
}

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
