import axios from '../../utils/axios';

const getCases = async ({
  clientId, start, end, tribunal, status, limit, page, jurisdiccion,
}) => {
  let query = '';
  if (clientId) query += `clientId=${clientId}&`;
  if (start) query += `start=${start}&`;
  if (end) query += `end=${end}&`;
  if (tribunal) query += `tribunal=${tribunal}&`;
  if (status) query += `status=${status}&`;
  if (limit) query += `limit=${limit}&`;
  if (page) query += `page=${page}&`;
  if (jurisdiccion) query += `jurisdiccion=${jurisdiccion}&`;
  const { data } = await axios.get(`/api/cases?${query}`);
  return data;
};

const getCasesByKey = async (search, limit, page, clientId) => {
  const { data } = await axios.get(`/api/cases/search?key=${search}&limit=${limit}&page=${page}&clientId=${clientId}`);
  return data;
};

const getClientCases = async (ids) => {
  const { data } = await axios.get(`/api/cases/clients?ids=${ids}`);
  return data;
};

const getUserCase = async (id) => {
  const { data } = await axios.get(`/api/cases/users/${id}`);
  return data;
};

const putBlockCase = async (id, userId, block) => {
  const { data } = await axios.put('/api/cases/block', { id, userId, block });
  return data;
};

export {
  getCases,
  getCasesByKey,
  getClientCases,
  getUserCase,
  putBlockCase,
};
