import { getTribunalsByClient } from '../requests/api/tribunal';

const tribunalsPerClient = async (clientId) => {
  try {
    const response = await getTribunalsByClient(clientId);
    const { tribunals } = response;
    return tribunals;
  } catch {
    return null;
  }
};

const tribunalsById = () => {
};

export {
  tribunalsPerClient,
  tribunalsById,
};
