import React, { useState, useRef } from 'react';
import { capitalCase } from 'change-case';
import {
  Box,
  Button,
  Popover,
  TextField,
  Typography,
} from '@mui/material';
import useSettings from '../../../hooks/useSettings';
import { THEMES } from '../../../constants';

import useStyles from './styles';

function Settings() {
  const classes = useStyles();
  const ref = useRef(null);
  const { settings, saveSettings } = useSettings();
  const [isOpen, setOpen] = useState(false);
  const [values, setValues] = useState({
    direction: settings.direction,
    theme: settings.theme,
  });

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (field, value) => {
    setValues({
      ...values,
      [field]: value,
    });
  };

  const handleSave = () => {
    saveSettings(values);
    setOpen(false);
  };

  return <>
    <Popover
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      classes={{ paper: classes.popoverSettings }}
      anchorEl={ref.current}
      onClose={handleClose}
      open={isOpen}
    >
      <Typography
        variant="h4"
        color="textPrimary"
      >
        Ajustes
      </Typography>
      <Box mt={2}>
        <TextField
          fullWidth
          label="Tema"
          name="theme"
          onChange={(event) => handleChange('theme', event.target.value)}
          select
          SelectProps={{ native: true }}
          value={values.theme}
          variant="outlined"
        >
          {Object.keys(THEMES).map((theme) => (
            <option
              key={theme}
              value={theme}
            >
              {capitalCase(theme)}
            </option>
          ))}
        </TextField>
      </Box>
      <Box mt={2}>
        <Button
          variant="contained"
          color="secondary"
          fullWidth
          onClick={handleSave}
        >
          Guardar
        </Button>
      </Box>
    </Popover>
  </>;
}

export default Settings;
