import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 210,
    backgroundColor: '#222A35',
  },
  desktopDrawer: {
    width: 210,
    top: 64,
    height: 'calc(100% - 64px)',
    backgroundColor: '#222A35',
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64,
  },
  logo: {
    maxWidth: '300px',
    maxHeight: '100px',
  },
  item: {
    display: 'block',
    paddingTop: 0,
    paddingBottom: 0,
  },
  itemLeaf: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
    '&:hover': {
      background: '#FCC561',
    },
  },
  button: {
    color: theme.palette.text.tertiary,
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
  },
  buttonLeaf: {
    color: 'white',
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightRegular,
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1),
  },
  title: {
    marginRight: 'auto',
  },
  active: {
    color: theme.palette.text.primary,
    backgroundColor: '#FCC561',
    borderRadius: 0,
  },
  cardHeader: {
    backgroundColor: '#FCC561',
  },
  cardNoPaddingContent: {
    padding: '50px',
  },
  floatingCard: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#fff',
    height: '400px',
    width: '600px',
  },
  clientItem: {
    margin: '5px',
  },
  clientsChangeText: {
    cursor: 'pointer',
  },
  changeText: {
    '&:hover': {
      borderBottom: '1px solid #FFF',
    },
  },
}));

export default useStyles;
