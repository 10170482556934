import React from 'react';
import PropTypes from 'prop-types';
import { Box, Fade } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Close } from '@mui/icons-material';

const FloatingWindow = ({
  open, children, setOpen, transform,
}) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: theme.palette.background.paper,
      position: 'fixed',
      transform, // Calcula la posición del respecto al CustomHeader
    },
  }));

  const classes = useStyles();

  return (
    <>
      <Fade in={open}>
        <Box
          sx={{ boxShadow: '2px 2px 4px 4px rgba(0, 0, 0, 0.2)', zIndex: '10' }}
          className={classes.root}
        >
          <Box sx={{
            pt: 1, pr: 1, display: 'flex', justifyContent: 'flex-end',
          }}>
            <div style={{ cursor: 'pointer' }} onClick={() => setOpen(false)}>
              <Close />
            </div>
          </Box>
          {children}
        </Box>
      </Fade>
    </>
  );
};

FloatingWindow.propTypes = {
  children: PropTypes.node.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  transform: PropTypes.string.isRequired,
};

export default FloatingWindow;
