import React, {
  useState,
  useEffect,
} from 'react';
import { withRouter } from 'react-router-dom';
import {
  Box,
  Container,
} from '@mui/material';
import PropTypes from 'prop-types';
import Page from '../../../../components/Layout/Page';
import useIsMountedRef from '../../../../hooks/useIsMountedRef';
import Header from './Header';
import UserEditForm from './UserEditForm';

import useStyles from './styles';

import { getUserDetail } from '../../../../requests/api/users';

function UserEditView(props) {
  const classes = useStyles();
  const isMountedRef = useIsMountedRef();
  const [user, setUser] = useState();

  useEffect(() => {
    const getUser = async () => {
      const response = await getUserDetail(props.match.params.id);
      if (isMountedRef.current) {
        setUser(response);
      }
    };

    getUser();
    // eslint-disable-next-line
  }, [props]);

  if (!user) {
    return null;
  }

  return (
    <Page
      className={classes.root}
      title="BSS Abogados | Editar Usuario"
    >
      <Container maxWidth={false}>
        <Header />
        <Box mt={3}>
          <UserEditForm user={user} />
        </Box>
      </Container>
  </Page>
  );
}

UserEditView.propTypes = {
  match: PropTypes.any,
};

export default withRouter(UserEditView);
