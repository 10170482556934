import React from 'react';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import {
  Box,
  Button,
  TextField,
  FormHelperText,
  Link,
  // Checkbox,
  // Link,
} from '@mui/material';

import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';

import handleApiResponse from '../../utils/http';

const { login } = require('../../actions/accountActions');

function LoginForm({ className, onSubmitSuccess, ...rest }) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const handleOnSubmit = async (values, { setSubmitting }) => {
    try {
      const response = await dispatch(login(values.email, values.password));
      setSubmitting(false);
      if (response.success) {
        onSubmitSuccess();
      }
    } catch (error) {
      handleApiResponse(enqueueSnackbar, error, false);
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string().required('Email requerido'),
        password: Yup.string().max(255).required('Contraseña requerida'),
      })}
      onSubmit={handleOnSubmit}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form
          noValidate
          onSubmit={handleSubmit}
          {...rest}
        >
          <TextField
          InputProps={{
            style: {
              borderWidth: '1px',
              borderStyle: 'solid',
              borderColor: touched.email && errors.email ? 'transparent' : 'black',
              borderRadius: 0,
              background: '#f2f2f2',
            },
          }}
            error={Boolean(touched.email && errors.email)}
            color="secondary"
            fullWidth
            autoFocus
            helperText={touched.email && errors.email}
            placeholder="Email"
            margin="normal"
            name="email"
            onBlur={handleBlur}
            onChange={handleChange}
            type="email"
            value={values.email}
            variant="outlined"
          />
          <TextField
          InputProps={{
            style: {
              borderWidth: '1px',
              borderStyle: 'solid',
              borderColor: touched.email && errors.email ? 'transparent' : 'black',
              borderRadius: 0,
              background: '#f2f2f2',
            },
          }}
            error={Boolean(touched.password && errors.password)}
            fullWidth
            color="secondary"
            helperText={touched.password && errors.password}
            placeholder="Contraseña"
            margin="normal"
            name="password"
            onBlur={handleBlur}
            onChange={handleChange}
            type="password"
            value={values.password}
            variant="outlined"
          />
          <Box sx={{
            display: 'flex',
            justifyContent: 'space-between',
            p: 1,
            m: 1,
            bgcolor: 'background.paper',
            borderRadius: 1,
          }}>
            {/* <label className={className.checkBoxLabel}>
              <Checkbox
                name="rememberMe"
                color="primary"
              />
              Recuérdame
            </label> */}
            <Link className={className.link}
            href="/recover-password" color="inherit">¿Olvidaste la contraseña?</Link>
          </Box>
          <Box mt={2}>
            <Button
              className={className.button}
              disabled={isSubmitting}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
            >
              Iniciar Sesión
            </Button>
            {errors.submit && (
              <Box mt={3}>
                <FormHelperText error>
                  {errors.submit}
                </FormHelperText>
              </Box>
            )}
          </Box>
        </form>
      )}
    </Formik>
  );
}

LoginForm.propTypes = {
  className: PropTypes.object,
  onSubmitSuccess: PropTypes.func,
};

LoginForm.defaultProps = {
  onSubmitSuccess: () => { },
};

export default LoginForm;
