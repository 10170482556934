/* eslint-disable no-underscore-dangle */
import axios from '../../utils/axios';

const getBankruptsClients = async (queries) => {
  const { data } = await axios.get('/api/bankrupts/clients', { params: queries });
  return data;
};

const deleteBankruptClients = async (ids) => {
  const { data } = await axios.delete('/api/bankrupts/clients', { data: { ids } });
  return data;
};

const getBankruptsClientsExport = async (queries) => {
  const { data } = await axios.get('/api/bankrupts/clients/file/export', { params: { ...queries } });
  return data;
};

const postBankruptsClientsImport = async ({ rutCliente, dvCliente, file }) => {
  const formData = new FormData();
  formData.append('file', file);
  const { data } = await axios.post('/api/bankrupts/clients/file/upload', formData,
    {
      params: { rutCliente, dvCliente },
      headers: {
        'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
      },
    });

  return data;
};

const postAddNewClient = async ({ newClient }) => {
  await axios.post('/api/bankrupts/clients/new', newClient);
};

const getBankruptsNotifications = async (queries) => {
  const { data } = await axios.get('/api/bankrupts/notifications', { params: queries });
  return data;
};

const postBankruptsNotificationAddEmail = async ({ newEmail }) => {
  await axios.post('/api/bankrupts/notifications/new', newEmail);
};

const deleteBankruptNotifications = async (ids) => {
  const { data } = await axios.delete('/api/bankrupts/notifications', { data: { ids } });
  return data;
};

export {
  getBankruptsClients,
  deleteBankruptClients,
  getBankruptsClientsExport,
  postBankruptsClientsImport,
  postAddNewClient,
  getBankruptsNotifications,
  postBankruptsNotificationAddEmail,
  deleteBankruptNotifications,
};
