import React, {
  useState,
  useEffect,
  useRef,
} from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Slide,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import moment from 'moment';

import Page from '../../components/Layout/Page';
import Header from './Header';

import useStyles from './styles';
import { getAllCases, getCaseByKey } from '../../services/casesService';
import Result from './Result';
import Details from './Details/Details';
import { tribunalsPerClient } from '../../services/tribunalService';

const CausasListView = () => {
  const classes = useStyles();
  const nodeRef = useRef(null);
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useSelector((state) => state.account);

  const [cases, setCases] = useState([]);
  const [casesCount, setCasesCount] = useState(0);
  const [selectedCase, setSelectedCase] = useState(null);
  const [open, setOpen] = useState(false);
  const [hide, setHide] = useState(false);
  const [page, setPage] = useState(1);
  const [rowsPerPage] = useState(10);
  const [tribunals, setTribunals] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState({
    flag: false,
    text: '',
  });

  const [filters, setFilters] = useState({
    startDate: null,
    endDate: null,
    status: null,
    tribunal: null,
    jurisdiccion: null,
  });

  useEffect(() => {
    const searchByKey = async () => {
      setIsLoading(true);
      try {
        const casesByKey = await getCaseByKey(search.text, 10, page, user?.client?.idPersona);
        setCases(casesByKey.cases);
        setCasesCount(casesByKey.count);
      } catch {
        enqueueSnackbar('Error al obtener las causas', {
          variant: 'error',
        });
      }
      setIsLoading(false);
    };
    if (search?.text?.length) searchByKey();
  }, [search, page]);

  useEffect(() => {
    const getData = async () => {
      try {
        const data = await getAllCases({
          clientId: user.client.idPersona,
          start: filters.startDate ? moment(filters.startDate).format('YYYY-MM-DD') : null,
          end: filters.endDate ? moment(filters.endDate).format('YYYY-MM-DD') : null,
          tribunal: filters.tribunal,
          status: filters.status,
          jurisdiccion: filters.jurisdiccion,
          limit: 10,
          page,
        });
        setCases(data.cases || []);
        setCasesCount(data.count || 0);
      } catch {
        enqueueSnackbar('Error al obtener las causas', {
          variant: 'error',
        });
      }
    };

    const getTribunals = async () => {
      try {
        const data = await tribunalsPerClient(user.client.idPersona);
        setTribunals(data);
      } catch (err) {
        enqueueSnackbar('Error al obtener los tribunales', {
          variant: 'error',
        });
      }
    };

    const loadData = async () => {
      setIsLoading(true);
      await getData();
      await getTribunals();
      setIsLoading(false);
    };
    if (user?.client && !search.length) {
      loadData();
    }
  }, [user, page, filters]);

  useEffect(() => {
    setPage(1);
  }, [filters]);

  useEffect(() => {
    setPage(1);
  }, [user?.client]);

  return (
    <>
      <Page
        className={classes.root}
        title="BSS Abogados | Causas"
      >
        <Box
          ref={nodeRef}
          className={classes.boxRow}>
          <Container
            maxWidth={false}
            className={classes.container}
          >
            <Header
              filters={filters}
              setFilters={setFilters}
              tribunals={tribunals}
              user={user}
              setSearch={setSearch}
            />
            { isLoading ? <div className={classes.spinnerContainer}>
              <CircularProgress />
            </div> : <Result
              cases={cases}
              setSelectedCase={setSelectedCase}
              open={open}
              setHide={setHide}
              setOpen={setOpen}
              classes={classes}
              page={page}
              setPage={setPage}
              rowsPerPage={rowsPerPage}
              casesCount={casesCount}
            />}
          </Container>
          <Button
            onClick={() => {
              setOpen(!open);
              setTimeout(() => {
                setHide(!open);
              }, 150);
            }}
            className={classes.detailsButton}
            variant='contained'>
            {open ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
          </Button>
          {hide ? <Slide direction="left" ref={nodeRef.current} in={open}>
            <Box
              className={classes.boxRow}>
              <Details
                cases={cases}
                selectedCase={selectedCase}
                classes={classes}
                open={open} />
            </Box>
          </Slide> : null}
        </Box>
      </Page>
    </>
  );
};

export default CausasListView;
