import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.background.dark,
  },
  backButton: {
    marginLeft: theme.spacing(2),
  },
  card: {
    overflow: 'visible',
    display: 'flex',
    position: 'relative',
  },
  content: {
    padding: theme.spacing(5),
    textAlign: 'center',
  },
  icon: {
    backgroundColor: '#22335E',
    color: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
    position: 'absolute',
    top: -32,
    left: theme.spacing(3),
    height: 64,
    width: 64,
  },
  media: {
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    padding: theme.spacing(3),
    color: theme.palette.common.white,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('lg')]: {
      display: 'none',
    },
  },
  logo: {
    maxWidth: '300px',
    maxHeight: '100px',
  },
  grid: {
    minHeight: '100%',
    alignContent: 'center',
    alignItems: 'center',
  },
  container: {
    minHeight: '100%',
  },
  button: {
    minWidth: '250px',
    minHeight: '50px',
    backgroundColor: '#FCC561',
    color: '#222A35',
    '&:hover': {
      background: '#a9a9a9',
    },
    borderRadius: 0,
  },
  loginImage: {
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    position: 'relative',
    background: `
      linear-gradient(rgba(0,0,0,0.7), rgba(0,0,0,0.7)), 
      url(img/login_image.jpg)
    `,
    [theme.breakpoints.down('sm')]: {
      objectFit: 'contain',
      height: '200px',
    },
  },

  checkBoxLabel: { fontFamily: 'Open Sans', fontSize: '16px' },
  formInput: {
    borderRadius: 0,
    border: '1px solid #222A35',
    marginLeft: '2%',
    marginRight: '2%',
  },
  link: {
    color: '#222A35',
    fontFamily: 'Open Sans',
  },
  logoImage: {
    objectFit: 'cover',
    position: 'relative',
    zIndex: 2,
    [theme.breakpoints.down('sm')]: {
      objectFit: 'contain',
      height: '50%',
      width: '50%',
    },
  },
  recoveryForm: {
    paddingLeft: '10%',
    paddingRight: '10%',
  },
}));

export default useStyles;
