import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box, Button, CircularProgress, MenuItem, Select, SvgIcon, TextField, Typography,
} from '@mui/material';
import { Download, Filter } from 'react-feather';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { convertArrayToCSV } from 'convert-array-to-csv';
import moment from 'moment';
import { useSnackbar } from 'notistack';

import useStyles from './styles';
import CustomHeader from '../../components/Layout/ CustomHeader';
import HeaderButton from '../../components/Layout/HeaderButton';
import FloatingWindow from '../../components/Custom/FloatWindow';
import { getAllCases } from '../../services/casesService';
import downloadExcel from '../../utils/xlsx';
import {
  TERMINADA, FALLADA, ACTIVA, SUSPENDIDA,
} from '../../utils/const';
import SearchBox from '../../components/Custom/SearchBox';

function Header({
  tribunals, className, filters, setFilters, user, setSearch,
}) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [open, setOpen] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedTribunal, setSelectedTribunal] = useState('');
  const [selectedJurisdiction, setSelectedJurisdiction] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const uniqueJurisdictions = [...new Set(tribunals?.map((t) => t.jurisdiccion))];

  const filterIcon = () => (<SvgIcon
    fontSize="small"
    className={classes.actionIcon}
  >
    <Filter />
  </SvgIcon>);

  const downloadIcon = () => {
    if (isLoading) return (<CircularProgress sx={{ color: 'white' }} size={22} />);
    return (<SvgIcon
    fontSize="small"
    className={classes.actionIcon}
  >
     <Download />
  </SvgIcon>);
  };

  const openFilters = () => setOpen(!open);

  const closeFilters = () => setOpen(false);

  const downloadFile = async () => {
    try {
      setIsLoading(true);
      const { cases } = await getAllCases({
        clientId: user.client.idPersona,
        start: filters.startDate ? moment(filters.startDate).format('YYYY-MM-DD') : '',
        end: filters.endDate ? moment(filters.endDate).format('YYYY-MM-DD') : '',
        tribunal: filters.tribunal,
        status: filters.status,
        jurisdiccion: filters.jurisdiccion,
        limit: 1000000,
        page: 1,
      });
      const data = cases.flat().map((c) => ({
        Estado: c.estadoCausa,
        Genero: c.genero,
        Numero: c.numero,
        Año: c.ano,
        Tribunal: c.tribunal,
        Contraparte: c.contraparte,
        Rut: c.rut,
        'Tipo causa': c.tipo,
        'Estado de avance': c.estado,
        'Fecha de inicio': c.fecIngreso,
        'Fecha último movimiento': c.fecha,
        Comentario: c.comentario,
      }));
      downloadExcel(data, 'causas');
    } catch {
      enqueueSnackbar('Error al obtener las causas', {
        variant: 'error',
      });
    }
    setIsLoading(false);
  };

  return (
    <CustomHeader
      classes={classes}
      className={className}
      title="Causas"
    >
      <SearchBox
        setSearch={setSearch}
        full={false}
      />
      <HeaderButton
        icon={filterIcon()}
        text="Filtrar Causas"
        onClick={openFilters}
      />
      <HeaderButton
        icon={downloadIcon()}
        text="Descargar Causas"
        onClick={downloadFile}
      />
      <FloatingWindow
        open={open}
        setOpen={setOpen}
        transform='translate(-8% , 54.5%)'
      >
        <Box className={{ ...classes.boxFilter, minWidth: '500px' }}>
          <Typography className={classes.filterTitle} variant="subtitle1">Rango de fechas</Typography>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Box sx={{ alignItems: 'center' }} className={classes.boxRow}>
              <DesktopDatePicker
                className={classes.datePickerLeft}
                label="Fecha inicio"
                inputFormat="DD/MM/YYYY"
                value={startDate}
                onChange={(date) => {
                  setStartDate(date?.toDate());
                }}
                renderInput={(params) => <TextField {...params} />}
              />
              <div> - </div>
              <DesktopDatePicker
                className={classes.datePickerRight}
                label="Fecha termino"
                inputFormat="DD/MM/YYYY"
                value={endDate}
                onChange={(date) => {
                  setEndDate(date?.toDate());
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </Box>
          </LocalizationProvider>
          <Typography className={classes.filterTitle} variant="subtitle1">Estado de causas</Typography>
          <Select value={selectedStatus}
          onChange={(event) => setSelectedStatus(event.target.value)}
          className={classes.filterSelect}>
            <MenuItem value={ TERMINADA }>Terminada</MenuItem>
            <MenuItem value={ ACTIVA }>Activa</MenuItem>
            <MenuItem value={ FALLADA }>Fallada</MenuItem>
            <MenuItem value={ SUSPENDIDA }>Suspendida</MenuItem>
          </Select>
          <Typography className={classes.filterTitle} variant="subtitle1">Jurisdicción</Typography>
          <Select value={selectedJurisdiction} className={classes.filterSelect}
          onChange={(event) => setSelectedJurisdiction(event.target.value)}>
            { uniqueJurisdictions?.map((j, i) => (
              <MenuItem key={i} value={j}>{j}</MenuItem>
            ))}
          </Select>
          <Typography className={classes.filterTitle} variant="subtitle1">Tribunal</Typography>
          <Select value={selectedTribunal} className={classes.filterSelect}
          onChange={(event) => setSelectedTribunal(event.target.value)}>
          { tribunals?.map((tribunal, i) => (
              <MenuItem key={i} value={tribunal.codTribunal}>{tribunal.tribunal}</MenuItem>
          ))}
          </Select>
          <Box className={classes.buttonBox}>
            <Button className={classes.button}
              onClick={() => {
                setFilters({
                  startDate: null,
                  endDate: null,
                  tribunal: '',
                  jurisdiccion: '',
                  status: '',
                });
                closeFilters();
                setStartDate(null);
                setEndDate(null);
                setSelectedTribunal('');
                setSelectedJurisdiction('');
                setSelectedStatus('');
              }}>
              DESHACER FILTROS
            </Button>
            <Button className={classes.yellowButton}
              onClick={() => {
                setSearch('');
                closeFilters();
                setFilters({
                  startDate,
                  endDate,
                  tribunal: selectedTribunal,
                  jurisdiccion: selectedJurisdiction,
                  status: selectedStatus,
                });
              }}>
              APLICAR FILTROS
            </Button>
          </Box>
        </Box>
      </FloatingWindow>
    </CustomHeader>
  );
}

Header.propTypes = {
  className: PropTypes.object,
  filters: PropTypes.object.isRequired,
  setFilters: PropTypes.func.isRequired,
  tribunals: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
  setSearch: PropTypes.func.isRequired,
};

export default Header;
