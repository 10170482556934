const TERMINADA = 'TERMINADA';
const FALLADA = 'FALLADA';
const ACTIVA = 'ACTIVA';
const SUSPENDIDA = 'SUSPENDIDA';
const CLIENT_VIEW = '/quiebras/clientes';
const NOTIFICATIONS_VIEW = '/quiebras/notificaciones';

const PERSONA_JURIDICA = 'juridica';
const PERSONA_NATURAL = 'natural';

module.exports = {
  TERMINADA,
  FALLADA,
  ACTIVA,
  SUSPENDIDA,
  CLIENT_VIEW,
  NOTIFICATIONS_VIEW,
  PERSONA_JURIDICA,
  PERSONA_NATURAL,
};
