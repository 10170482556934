import React,
{
  useEffect,
  useState,
} from 'react';
import JSZip from 'jszip';
import { useSnackbar } from 'notistack';
import FileSaver from 'file-saver';

import PropTypes from 'prop-types';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {
  Box, Divider, Grid, Pagination, Typography,
} from '@mui/material';
import moment from 'moment';
import { format, validate } from 'rut.js';
import { Download } from '@mui/icons-material';

import thousandSeparator from '../../utils/thousandSeparator';
import { getBillingBlob } from '../../requests/api/docs';

const Result = ({
  billings,
  billingsCount,
  classes,
  page,
  setPage,
  rowsPerPage,
  amounts,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [totalPages, setTotalPages] = useState(0);

  const columnWidths = {
    causa: '15%',
    contraparte: '10%',
    rut: '12%',
    fecha: '10%',
    descripcion: '10%',
    movimiento: '10%',
    saldo: '10%',
    download: '5%',
  };

  useEffect(() => {
    setTotalPages(Math.floor(billingsCount / rowsPerPage));
  }, [billingsCount]);

  // const downloadFile = (blob, fileName) => {
  //   const link = document.createElement('a');
  //   link.href = URL.createObjectURL(blob);
  //   link.download = fileName;
  //   link.click();
  //   URL.revokeObjectURL(link.href);
  // };

  const bufferToBlob = (buffer, contentType) => {
    const byteArray = new Uint8Array(buffer.data);
    return new Blob([byteArray], { type: contentType });
  };

  const handleDownload = async (billing) => {
    try {
      // Find blob in database
      const documentos = await getBillingBlob(billing.idCargo);
      if (!documentos?.length) {
        enqueueSnackbar('No hay documentos', {
          variant: 'warning',
        });
        return;
      }

      const fileExtension = 'zip';
      const contentType = `application/${fileExtension}`;

      const zip = new JSZip();
      for (let i = 0; i < documentos.length; i += 1) {
        const doc = documentos[i];
        const blob = bufferToBlob(doc.documento, contentType);
        zip.file(doc.NomDocumento, blob, { binary: true });
      }
      zip
        .generateAsync({ type: 'blob' })
        .then((content) => {
          FileSaver.saveAs(content, `Cuentas-${billing.causa}.zip`);
        })
        .catch(() => {
          enqueueSnackbar('Error generando archivo zip', {
            variant: 'error',
          });
        })
        .then(() => {
          enqueueSnackbar(`${documentos?.length} documentos descargados`, {
            variant: 'success',
          });
        });
    } catch {
      enqueueSnackbar('Error al descargar el archivo', { variant: 'error' });
    }
  };

  const generateRows = () => {
    const rows = [];
    for (let j = 0; j < billings?.length; j += 1) {
      const billing = billings[j];
      const row = (<TableRow key={j}
        className={classes.tableRow}
      >
        <TableCell style={{ width: columnWidths.causa }} align="left" className={classes.tableCell}>
          {billing.causa}
        </TableCell>
        <TableCell align="center" style={{ width: columnWidths.contraparte }} className={classes.tableCell}>
          {billing.contraparte}
        </TableCell>
        <TableCell align="center" style={{ width: columnWidths.rut }} className={classes.tableCell}>
          {validate(billing.rut) ? format(billing.rut) : billing.rut}
        </TableCell>
        <TableCell align="center" style={{ width: columnWidths.fecha }} className={classes.tableCell}>
          {moment(billing.fecha).format('DD/MM/YYYY')}
        </TableCell>
        <TableCell align="center" style={{ width: columnWidths.descripcion }} className={classes.tableCell}>
          {billing.descripcion}
        </TableCell>
        <TableCell align="center" style={{ width: columnWidths.movimiento }} className={classes.tableCell}>
          <Typography className={billing.movimiento < 0 ? classes.amountRed : classes.amountGreen}>
            ${thousandSeparator(billing.movimiento, '.')}
          </Typography>
        </TableCell>
        <TableCell align="center" style={{ width: columnWidths.saldo }} className={classes.tableCell}>
          <Typography className={billing.saldo < 0 ? classes.amountRed : classes.amountGreen}>
            ${thousandSeparator(billing.saldo, '.')}
          </Typography>
        </TableCell>
        <TableCell align="center" style={{ width: columnWidths.download }} className={classes.tableCell}>
            { !billing.isBlock && (
            <div className={classes.downloadButton} onClick={() => handleDownload(billing)}>
              <Download />
            </div>) }
        </TableCell>
      </TableRow>);

      rows.push(row);
    }
    return rows;
  };

  return (
    <>
      <TableContainer sx={{
        mt: 2, pr: 3, borderRadius: 2,
      }}>
        <Table size="small" aria-label="customized table">
          <TableHead>
            <TableRow className={classes.tableRowHeaderDark}>
              <TableCell className={classes.tableCellHeaderDark}
                style={{ width: columnWidths.causa }}>
                Causa</TableCell>
              <TableCell
                className={classes.tableCellHeaderDark} style={{ width: columnWidths.contraparte }}>
                Contraparte</TableCell>
              <TableCell
                className={classes.tableCellHeaderDark} style={{ width: columnWidths.rut }}>
                RUT</TableCell>
              <TableCell
                className={classes.tableCellHeaderDark} style={{ width: columnWidths.fecha }}>
                Fecha</TableCell>
              <TableCell
                className={classes.tableCellHeaderDark}
                style={{ width: columnWidths.descripcion }}>
                Descripción</TableCell>
              <TableCell
                className={classes.tableCellHeaderDark} style={{ width: columnWidths.movimiento }}>
                Movimiento</TableCell>
              <TableCell
                className={classes.tableCellHeaderDark} style={{ width: columnWidths.saldo }}>
                Saldo</TableCell>
              <TableCell
                className={classes.tableCellHeaderDark} style={{ width: columnWidths.saldo }}>
                Descarga</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {generateRows()}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        sx={{ mt: 2, mb: 2, justifyContent: 'center' }}
        color="primary"
        count={totalPages}
        onChange={(e, v) => setPage(v)}
        page={page}
        size="large"
      />
      <Grid
      className={classes.rootHeader}
      container
      spacing={0.5}
    >
      <Grid item>
        <Box sx={{
          backgroundColor: '#1C2024',
          pl: 5,
          pr: 5,
          pt: 1.5,
          pb: 1.5,
        }}>
        <Typography
          variant="h6"
          color="white"
        >
         SALDO ACTUAL <span style={{ fontWeight: 'bold' }}>${thousandSeparator(amounts.current, '.')}</span>
        </Typography>
        </Box>
      </Grid>
      <Grid item>
        <Box sx={{
          backgroundColor: '#FCC561', pl: 5, pr: 5, pt: 1.5, pb: 1.5,
        }}>
        <Typography
          variant="h6"
          color="textPrimary"
        >
         TOTAL ABONOS ${thousandSeparator(amounts.totalIn, '.')}
        </Typography>
        </Box>
      </Grid>
      <Grid item>
        <Box sx={{
          backgroundColor: '#FCC561', pl: 5, pr: 5, pt: 1.5, pb: 1.5,
        }}>
        <Typography
          variant="h6"
          color="textPrimary"
        >
         TOTAL CARGOS ${thousandSeparator(amounts.totalOut, '.')}
        </Typography>
        </Box>
      </Grid>
      <Grid item>
        <Box sx={{
          backgroundColor: '#FCC561', pl: 5, pr: 5, pt: 1.5, pb: 1.5,
        }}>
        <Typography
          variant="h6"
          color="textPrimary"
        >
         SALDO DEL PERIODO ${thousandSeparator(amounts.balance, '.')}
        </Typography>
        </Box>
      </Grid>
    </Grid>
    <Divider sx={{ borderBottomWidth: 3, borderColor: '#FCC561' }} />
    </>
  );
};

Result.propTypes = {
  billings: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  billingsCount: PropTypes.number.isRequired,
  amounts: PropTypes.object.isRequired,
};

export default Result;
