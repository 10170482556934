import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  MenuItem,
  Autocomplete,
} from '@mui/material';
import { Redirect } from 'react-router';
import { useSelector } from 'react-redux';

import { postRegister } from '../../../../requests/api/auth';

import handleApiResponse from '../../../../utils/http';
import useStyles from './styles';
import { getAllClients } from '../../../../services/casesService';

function UserCreateForm({
  className,
  stores,
  ...rest
}) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [clients, setClients] = useState([]);
  const [clientsSelected, setClientsSelected] = useState([]);
  const { user } = useSelector((state) => state.account);
  const userAdmin = useSelector((state) => state.account.user);
  // TODO: Remove this when the super-admin role is ready
  const notSuperAdmin = user.email !== 'ariel@tubesoft.cl' && user.email !== 'ssanchez@bsslegal.cl';

  useEffect(() => {
    const getClients = async () => {
      const response = await getAllClients(userAdmin.clients.map((c) => c.idPersona));
      setClients(response.clients);
    };
    getClients();
  }, []);

  useEffect(() => {
    if (user && user.clients) {
      setClientsSelected(
        user.clients
          .map((c) => ({ label: `${c.nombres} ${c.paterno} ${c.materno}`, id: c.idPersona })),
      );
    }
  }, [user]);

  if (submitSuccess) {
    return <Redirect to="/administracion/users" />;
  }

  return (
    <Formik
      initialValues={{
        email: '',
        name: '',
        lastname: '',
        role: 'user',
        // password: '',
        // confirmPassword: '',
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().max(255).required('Debe ingresar un nombre'),
        lastname: Yup.string().max(255).required('Debe ingresar un apellido'),
        email: Yup.string().email('Email inválido').required('Debe ingresar email'),
        // password: Yup.string().max(255).required('Debe ingresar una contraseña'),
        // confirmPassword: Yup.string()
        //   .oneOf([Yup.ref('password'), null], 'Las contraseñas deben coincidir')
        //   .required('Debe confirmar su contraseña'),
      })}
      onSubmit={async (values, {
        setSubmitting,
      }) => {
        try {
          // Make API request
          const data = {
            name: values.name,
            lastname: values.lastname,
            isAdmin: values.role === 'admin',
            email: values.email,
            // password: values.password,
            clients: clientsSelected.map((c) => c.id),
          };
          const response = await postRegister(data);

          handleApiResponse(enqueueSnackbar, response, true);
          setSubmitSuccess(true);
          setSubmitting(false);
        } catch (error) {
          handleApiResponse(enqueueSnackbar, error, false); setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form
          className={clsx(classes.root, className)}
          onSubmit={handleSubmit}
          {...rest}
        >

          <Card>
            <CardContent>
              <Grid
                container
                spacing={3}
              >
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    error={Boolean(touched.name && errors.name)}
                    fullWidth
                    helperText={touched.name && errors.name}
                    label="Nombre"
                    name="name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.name}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    error={Boolean(touched.lastname && errors.lastname)}
                    fullWidth
                    helperText={touched.lastname && errors.lastname}
                    label="Apellido"
                    name="lastname"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.lastname}
                    variant="outlined"
                  />
                </Grid>
                {/* <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    error={Boolean(touched.password && errors.password)}
                    fullWidth
                    helperText={touched.password && errors.password}
                    label="Password"
                    name="password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.password}
                    variant="outlined"
                    type={'password'}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(touched.confirmPassword && errors.confirmPassword)}
                    fullWidth
                    helperText={touched.confirmPassword && errors.confirmPassword}
                    label="Confirmar Password"
                    name="confirmPassword"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.confirmPassword}
                    variant="outlined"
                    type={'password'}
                  />
                </Grid> */}
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    error={Boolean(touched.email && errors.email)}
                    fullWidth
                    helperText={touched.email && errors.email}
                    label="Email"
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.email}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    disabled={notSuperAdmin}
                    fullWidth
                    label="Rol"
                    name="role"
                    onChange={handleChange}
                    select
                    value={values.role}
                    variant="outlined"
                    required
                  >
                    <MenuItem
                      value="admin"
                    >
                      ADMIN
                    </MenuItem>
                    <MenuItem
                      value="user"
                    >
                      USER
                    </MenuItem>
                  </TextField>
                </Grid>
                <Grid item
                  md={6}
                  xs={12} >
                  <Autocomplete
                    disableCloseOnSelect
                    multiple
                    id="clients-autocomplete"
                    options={clients.map((c) => ({ label: `${c.nombres} ${c.paterno} ${c.materno}`, id: c.idPersona }))}
                    getOptionLabel={(option) => option.label}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    filterSelectedOptions
                    value={clientsSelected}
                    onChange={(e, v) => {
                      setClientsSelected(v);
                    }}
                    limitTags={10}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Clientes"
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Box mt={2}>
                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Guardar
                </Button>
              </Box>
            </CardContent>
          </Card>
        </form>
      )}
    </Formik>
  );
}

UserCreateForm.propTypes = {
  className: PropTypes.object,
  stores: PropTypes.array,
};

export default UserCreateForm;
