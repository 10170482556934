import React from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@mui/styles/makeStyles';

import { Box, Modal, Typography } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  modalWrapper: {

  },
  modalContent: {
    backgroundColor: theme.palette.background.default,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    boxShadow: theme.shadows,
    '&:focus': {
      outline: 'none',
    },
  },
  modalHeader: {
    display: 'flex',
    padding: '5px',
    backgroundColor: theme.palette.secondary.main,
  },
  modalHeaderTitle: {
    fontWeight: '500',
    marginLeft: '15px',
  },
  modalChildren: {
    display: 'flex',
    justifyContent: 'center',
  },
  modalClose: {
    cursor: 'pointer',
    position: 'absolute',
    right: '8px',
    top: '0px',
    fontSize: '30px',
    '&:hover': {
      color: theme.palette.primary.darker,
    },
  },
}));

const Popup = ({
  open,
  onClose,
  children,
  title,
  maxWidth = '700px',
}) => {
  const styles = useStyles();

  const handleClickClose = async () => {
    await onClose();
  };

  return (
    <Box className={styles.modalWrapper}>
      <Modal
        open={open}
        onClose={onClose}
        disableScrollLock
      >
        <Box className={styles.modalContent} style={{ maxWidth }}>
          <Box
            className={styles.modalClose}
            onClick={handleClickClose}
          >
            &times;
          </Box>
          <Box className={styles.modalHeader}>
            {title ? (
              <Typography className={styles.modalHeaderTitle}>
                {title}
              </Typography>
            ) : null}
          </Box>
          <Box className={styles.modalChildren}>
            {children}
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

Popup.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  closeColor: PropTypes.string,
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  maxWidth: PropTypes.string,
};

export default Popup;
