import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box, Button, Typography, CircularProgress,
} from '@mui/material';

import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead, { tableHeadClasses } from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { AccessTime, FileDownload, FileUpload } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import moment from 'moment';

import History from './History';
import Involved from './Involved';

import { getDocumentBlob } from '../../../requests/api/docs';

const StyledTableHead = styled(TableHead)(() => ({
  [`&.${tableHeadClasses.root}`]: {
    height: 30,
  },
}));

const Details = ({ classes, selectedCase }) => {
  const titleFirst = 'INVOLUCRADOS';
  const titleSecond = 'ESTADO DE AVANCE';
  const titleThird = 'DOCUMENTOS';

  const { enqueueSnackbar } = useSnackbar();
  const [indexInvolved, setIndexInvolved] = useState(0);
  const [indexFiles, setIndexFiles] = useState(0);
  const [openModalHistory, setOpenModalHistory] = useState(false);
  const [openModalInvolved, setOpenModalInvolved] = useState(false);
  const [fileDownloading, setFileDownloading] = useState(false);

  const uploadFile = () => {
    if (indexFiles) {
      // TODO: upload file logic
    }
  };

  const bufferToBlob = (buffer, contentType) => {
    const byteArray = new Uint8Array(buffer.data);
    return new Blob([byteArray], { type: contentType });
  };

  const downloadFile = (blob, fileName) => {
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
    URL.revokeObjectURL(link.href);
  };

  const handleDownload = async () => {
    setFileDownloading(true);

    const file = selectedCase?.documentos[indexFiles];

    // Find blob in database
    const documento = await getDocumentBlob(file.idDocumentoAccion);

    if (!documento) {
      enqueueSnackbar('Error descargando documento', {
        variant: 'error',
      });
      setFileDownloading(false);
      return;
    }

    const fileExtension = documento.TipoDocumento.replace('.', '');
    const fileName = `${documento.NomDocumento}.${fileExtension}`;
    const contentType = `application/${fileExtension}`;

    const blob = bufferToBlob(documento.documento, contentType);
    downloadFile(blob, fileName);

    setFileDownloading(false);
  };

  return (
    <>
      <Box className={classes.detailsContent}>
        <Typography
          variant="h4"
          className={classes.detailsTitle}
        >
          {titleFirst.toUpperCase()}
        </Typography>
        <TableContainer className={classes.detailsTableContainer}>
          <Table aria-label="customized table">
            <StyledTableHead>
              <TableRow className={classes.tableRowHeader}>
                <TableCell className={classes.tableCellHeader} align="center">Involucrado</TableCell>
                <TableCell className={classes.tableCellHeader} align="center">Calidad</TableCell>
                <TableCell className={classes.tableCellHeader} align="center">Observaciones</TableCell>
              </TableRow>
            </StyledTableHead>
            <TableBody>
              {selectedCase?.involucrados?.map((involucrado, i) => {
                if (i === indexInvolved) {
                  return (
                    <TableRow
                      className={classes.tableRowDetails}
                      onClick={() => setIndexInvolved(i)}
                      onDoubleClick={() => setOpenModalInvolved(true)}
                      key={i}>
                      <TableCell className={classes.tableCellSelected} align="center">{involucrado.nombre}</TableCell>
                      <TableCell className={classes.tableCellSelected} align="center">{involucrado.calidad}</TableCell>
                      <TableCell className={classes.tableCellSelected} align="center">{involucrado.observaciones}</TableCell>
                    </TableRow>
                  );
                }
                return (
                  <TableRow
                    className={classes.tableRowDetails}
                    onClick={() => setIndexInvolved(i)}
                    onDoubleClick={() => setOpenModalInvolved(true)}
                    key={i}>
                    <TableCell className={classes.tableCell} align="center">{involucrado.nombre}</TableCell>
                    <TableCell className={classes.tableCell} align="center">{involucrado.calidad}</TableCell>
                    <TableCell className={classes.tableCell} align="center">{involucrado.observaciones}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <Typography
          variant="h4"
          className={classes.detailsTitle}
        >
          {titleSecond.toUpperCase()}
        </Typography>
        <TableContainer className={classes.detailsTableContainer}>
          <Table aria-label="customized table">
            <StyledTableHead>
              <TableRow className={classes.tableRowHeader}>
                <TableCell className={classes.tableCellHeader} align="center">Estado</TableCell>
                <TableCell className={classes.tableCellHeader} align="center">Fecha</TableCell>
                <TableCell className={classes.tableCellHeader} align="center">Comentario</TableCell>
              </TableRow>
            </StyledTableHead>
            <TableBody>
              {selectedCase?.acciones?.slice(0, 3).map((accion, i) => (
                <TableRow className={classes.tableRowDetails} key={i}>
                  <TableCell className={classes.tableCell} align="center">{accion?.estado}</TableCell>
                  <TableCell className={classes.tableCell} align="center">{moment(accion?.fecTermino).format('DD/MM/YYYY')}</TableCell>
                  <TableCell className={classes.tableCell} align="center">{accion?.observaciones}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Button
          onClick={() => setOpenModalHistory(true)}
          className={classes.doubleButton}
          variant='contained'
          color='primary' >
          <AccessTime sx={{ mr: 1 }} />
          HISTORIAL DE TRAMITACION
        </Button>
        <Typography
          variant="h4"
          className={classes.detailsTitle}
        >
          {titleThird.toUpperCase()}
        </Typography>
        <TableContainer className={classes.detailsTableContainer}>
          <Table aria-label="customized table">
            <StyledTableHead>
              <TableRow className={classes.tableRowHeader}>
                <TableCell className={classes.tableCellHeader} align="center">Nombre documento</TableCell>
                <TableCell className={classes.tableCellHeader} align="center">Fecha asignación</TableCell>
                <TableCell className={classes.tableCellHeader} align="center">Tipo de documento</TableCell>
              </TableRow>
            </StyledTableHead>
            <TableBody>

              {selectedCase?.documentos?.map((file, i) => {
                if (i === indexFiles) {
                  return (
                    <TableRow
                      className={classes.tableRowDetails}
                      onClick={() => setIndexFiles(i)}
                      key={i}>
                      <TableCell className={classes.tableCellSelected} align="center">{file.NomDocumento}</TableCell>
                      <TableCell className={classes.tableCellSelected} align="center">{moment(file.fecAsignacion).format('DD-MM-YYYY')}</TableCell>
                      <TableCell className={classes.tableCellSelected} align="center">{file.TipoDocumento}</TableCell>
                    </TableRow>
                  );
                }
                return (
                  <TableRow
                    className={classes.tableRowDetails}
                    onClick={() => setIndexFiles(i)}
                    key={i}>
                    <TableCell className={classes.tableCell} align="center">{file.NomDocumento}</TableCell>
                    <TableCell className={classes.tableCell} align="center">{moment(file.fecAsignacion).format('DD-MM-YYYY')}</TableCell>
                    <TableCell className={classes.tableCell} align="center">{file.TipoDocumento}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <Box className={classes.buttonBox}>
          <Button className={classes.button}
            disabled={true}
            onClick={uploadFile}>
            <FileUpload />
            SUBIR DOCUMENTO</Button>
          <Button className={classes.yellowButton}
            disabled={selectedCase?.documentos.length === 0 || fileDownloading}
            onClick={handleDownload}>
            {fileDownloading ? <CircularProgress size={20} style={{
              color: 'var(--brand-blue',
              marginRight: '10px',
            }} /> : <FileDownload style={{ marginRight: '10px' }} />}
            DESCARGAR DOCUMENTO
          </Button>
        </Box>
      </Box>
      <History
        data={selectedCase || {}}
        classes={classes}
        open={openModalHistory}
        setOpen={setOpenModalHistory} />
      <Involved
        data={{
          ...selectedCase?.involucrados[indexInvolved] || {},
        }}
        classes={classes}
        open={openModalInvolved}
        setOpen={setOpenModalInvolved}
      />
    </>
  );
};

Details.propTypes = {
  classes: PropTypes.object.isRequired,
  selectedCase: PropTypes.object,
  cases: PropTypes.array,
};

export default Details;
