import { colors } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  avatar: {
    height: 32,
    width: 32,
    marginRight: theme.spacing(1),
  },
  popover: {
    width: 200,
  },
  textColor: {
    color: theme.palette.text.tertiary,
  },
  root: {
    zIndex: theme.zIndex.drawer + 100,
    ...{
      backgroundColor: '#FCC561',
    },
  },
  toolbar: {
    minHeight: 64,
    backgroundColor: colors.common.white,
  },
  logo: {
    maxWidth: '300px',
    maxHeight: '50px',
    marginLeft: theme.spacing(2),
  },
  badge: {
    height: 10,
    width: 10,
    borderRadius: 5,
    marginTop: 10,
    marginRight: 5,
  },
  popoverSettings: {
    width: 320,
    padding: theme.spacing(2),
  },
  menuButton: {
    color: '#1C2024',
  },
}));

export default useStyles;
