import React, {
  useState,
  useEffect,
} from 'react';
import {
  Box,
  Container,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import moment from 'moment';

import { useSelector } from 'react-redux';
import Page from '../../../components/Layout/Page';
import Header from './Header';
import Results from './Results';

import useStyles from './styles';
import {
  deleteBankruptClients,
  getBankruptsClients,
  getBankruptsClientsExport,
  postAddNewClient,
  postBankruptsClientsImport,
} from '../../../requests/api/bankrupts';
import handleApiResponse from '../../../utils/http';
import downloadExcel from '../../../utils/xlsx';
import { CLIENT_VIEW, PERSONA_JURIDICA, PERSONA_NATURAL } from '../../../utils/const';

function ListView() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const account = useSelector((state) => state.account);

  const [view, setView] = useState(CLIENT_VIEW);
  const [refresh, setRefresh] = useState(0);
  const [rows, setRows] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [query, setQuery] = useState({});
  const [sort, setSort] = useState('fec_ingreso|desc');
  const [filters, setFilters] = useState({
    sort,
    startDate: null,
    endDate: null,
  });

  const [newClients, setNewClients] = useState([{
    rut: '',
    tipoPersona: PERSONA_JURIDICA,
    nombre: '',
    paterno: '',
    materno: '',
  }]);

  useEffect(() => {
    const getRows = async () => {
      try {
        const response = await getBankruptsClients({
          rutCliente: account?.user?.client?.rut || null,
          dvCliente: account?.user?.client?.dv || null,
          page,
          limit,
          query: query.text,
          sort: filters.sort,
          startDate: filters.startDate,
          endDate: filters.endDate,
        });
        if (response.data?.rows) {
          setRows(response.data.rows);
          setCount(response.data.count);
        }
      } catch (error) {
        handleApiResponse(enqueueSnackbar, error, false);
      }
    };
    getRows();
  }, [
    sort,
    query,
    page,
    limit,
    account,
    refresh,
    filters,
  ]);

  const uploadFile = async (file) => {
    try {
      const response = await postBankruptsClientsImport({
        rutCliente: account?.user?.client?.rut,
        dvCliente: account?.user?.client?.dv,
        file,
      });
      enqueueSnackbar(response.message, {
        variant: 'success',
      });

      setRefresh((prev) => prev + 1);
    } catch (error) {
      handleApiResponse(enqueueSnackbar, error, false);
    }
  };

  const downloadFile = async () => {
    try {
      const response = await getBankruptsClientsExport({
        rutCliente: account?.user?.client?.rut || null,
        dvCliente: account?.user?.client?.dv || null,
        query: query.text,
        sort: filters.sort,
        startDate: filters.startDate,
        endDate: filters.endDate,
      });
      const data = response.data.map((br) => {
        const row = {
          'Razon social': br.nombre || '',
          Nombre: '',
          Paterno: '',
          Materno: '',
          'Tipo persona': br.tipoPersona,
          Rut: `${br.rutConsult}-${br.dvConsult}`,
          'Fecha ingreso': br.fecIngreso ? moment(br.fecIngreso).format('DD/MM/YYYY') : '',
          'Fecha consulta': br.fecConsulta ? moment(br.fecConsulta).format('DD/MM/YYYY') : '',
        };
        if (br.tipoPersona === PERSONA_NATURAL) {
          row['Razon social'] = '';
          row.Nombre = br.nombre;
          row.Paterno = br.paterno;
          row.Materno = br.materno;
        }

        return row;
      });
      downloadExcel(data, 'boletin-bss');
    } catch (error) {
      handleApiResponse(enqueueSnackbar, error, false);
    }
  };

  const downloadDefaultFile = () => {
    try {
      const defaultRows = [
        {
          'Razon social': 'Empresa de Prueba',
          Nombre: '',
          Paterno: '',
          Materno: '',
          'Tipo persona': PERSONA_JURIDICA,
          Rut: '11111111-1',
        },
        {
          'Razon social': '',
          Nombre: 'Nombre de Prueba',
          Paterno: 'Apellido Paterno de Prueba',
          Materno: 'Apellido Materno de Prueba',
          'Tipo persona': PERSONA_NATURAL,
          Rut: '22222222-2',
        },
      ];
      downloadExcel(defaultRows, 'plantilla-importador-boletin');
    } catch (error) {
      handleApiResponse(enqueueSnackbar, error, false);
    }
  };

  const addNewClient = async () => {
    try {
      await postAddNewClient({
        newClient: {
          rutCliente: account?.user?.client?.rut || null,
          dvCliente: account?.user?.client?.dv || null,
          newClients,
        },
      });
      enqueueSnackbar('Se ha agregado el nuevo cliente', {
        variant: 'success',
      });
      setRefresh((prev) => prev + 1);
      return { success: true };
    } catch (error) {
      handleApiResponse(enqueueSnackbar, error, false);
      return { success: false };
    }
  };

  const deleteClients = async (clients) => {
    if (!account.user.isAdmin) {
      enqueueSnackbar('Debe ser administrador!', {
        variant: 'error',
      });
      return { success: false };
    }

    try {
      const response = await deleteBankruptClients(clients);
      handleApiResponse(enqueueSnackbar, response, true);
      setRefresh((prev) => prev + 1);
      return { success: true };
    } catch (error) {
      handleApiResponse(enqueueSnackbar, error, false);
      return { success: false };
    }
  };

  return (
    <Page
      className={classes.root}
      title="BSS Abogados | Boletín BSS"
    >
      <Container maxWidth={false}>
        <Header
          setFilters={setFilters}
          setQuery={setQuery}
          sort={sort}
          setSort={setSort}
          view={view}
          setView={setView}
          uploadFile={uploadFile}
          downloadFile={downloadFile}
          downloadDefaultFile={downloadDefaultFile}
          newClients={newClients}
          setNewClients={setNewClients}
          addNewClient={addNewClient}
        />
        <Box mt={2}>
          <Results
            view={view}
            rows={rows}
            count={count}
            page={page}
            setPage={setPage}
            limit={limit}
            setLimit={setLimit}
            deleteClients={deleteClients}
          />
        </Box>
      </Container>
    </Page>
  );
}

export default ListView;
