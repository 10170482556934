import React from 'react';
import PropTypes from 'prop-types';

function Logo({ isWhite, ...props }) {
  return (
    <img
      style={{
        opacity: 1,
      }}
      alt="Logo"
      src={isWhite ? '/img/logo_bss_white.png' : '/img/logo_bss.png'}
      {...props}
    />
  );
}

Logo.propTypes = {
  isWhite: PropTypes.bool,
};

export default Logo;
