import { getBillings } from '../requests/api/billing';

const getAllBillings = async ({
  clientId,
  start,
  end,
  demandedId,
  caseId,
  isIncome,
  isOutcome,
  startAmount,
  endAmount,
  limit,
  page,
}) => {
  try {
    const response = await getBillings({
      clientId,
      page,
      limit,
      start,
      end,
      demandedId,
      caseId,
      isIncome,
      isOutcome,
      startAmount,
      endAmount,
    });
    return {
      billings: response.billings || [],
      totalIncome: response.totalIncome,
      totalOutcome: response.totalOutcome,
      totalBalance: response.totalBalance,
      currentAmount: response.currentAmount,
      count: response.count,
      defendants: response.defendants,
      cases: response.cases,
    };
  } catch {
    return {
      billings: null,
      count: 0,
      message: 'Error al obtener las causas',
    };
  }
};

const getBillingById = () => {
};

export {
  getAllBillings,
  getBillingById,
};
