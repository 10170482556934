import axios from '../../utils/axios';

const getTribunalsById = () => {};

const getTribunalsByClient = async (clientId) => {
  const { data } = await axios.get(`/api/tribunal/clients/${clientId}`);
  return data;
};

export {
  getTribunalsById,
  getTribunalsByClient,
};
