import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  rootHeader: {},
  rootResults: {},
  queryField: {
    width: 500,
  },
  bulkOperations: {
    position: 'relative',
  },
  bulkActions: {
    paddingLeft: 4,
    paddingRight: 4,
    marginTop: 6,
    position: 'absolute',
    width: '100%',
    zIndex: 2,
    backgroundColor: '#222A35',
  },
  bulkAction: {
    marginLeft: theme.spacing(2),
    color: 'white',
    border: '1px solid white',
  },
  avatar: {
    height: 42,
    width: 42,
    marginRight: theme.spacing(1),
  },
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(2),
  },
  action: {
    marginBottom: theme.spacing(0),
    '& + &': {
      marginLeft: theme.spacing(0),
    },
  },
  actionIcon: {
    marginRight: theme.spacing(1),
  },
  subButtons: {
    display: 'flex',
    justifyContent: 'end',
  },
  boxFilter: {
    display: 'flex',
    flexDirection: 'column',
    border: '2px solid #d9d9d9',
    maxWidth: '400px',
  },
  filterTitle: {
    marginLeft: '5%',
    marginTop: '2%',
    marginBottom: '2%',
  },
  boxRow: {
    display: 'flex',
    flexDirection: 'row',
    minHeight: '100%',
  },
  datePickerLeft: {
    marginLeft: '5%',
    marginRight: '1%',
  },
  datePickerRight: {
    marginLeft: '1%',
    marginRight: '5%',
  },
  filterSelect: {
    borderRadius: 0,
    marginLeft: '5%',
    marginRight: '5%',
    marginBottom: '3%',
    maxHeight: '45px',
    minWidth: '90%',
  },
  buttonBox: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    marginBottom: '10px',
  },
  button: {
    minWidth: '250px',
    minHeight: '50px',
    backgroundColor: '#222A35',
    color: '#FFF',
    '&:hover': {
      background: '#a9a9a9',
    },
    borderRadius: 0,
  },
  yellowButton: {
    minWidth: '250px',
    minHeight: '50px',
    borderRadius: 0,
    backgroundColor: '#FCC561',
    color: '#222A35',
    '&:hover': {
      background: '#a9a9a9',
    },
  },
  tableHead: {
    backgroundColor: '#222A35',
  },
  tableHeadCell: {
    color: 'white',
  },
  modalCancelButton: {
    textAlign: 'center',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.text.tertiary,
    width: '100%',
    borderRadius: 0,
    '&:hover': {
      backgroundColor: theme.palette.primary.darker,
    },
  },
  modalAddButton: {
    textAlign: 'center',
    width: '100%',
    backgroundColor: theme.palette.secondary.main,
    borderRadius: 0,
    '&:hover': {
      backgroundColor: theme.palette.secondary.darker,
    },
  },
  modalTextField: {
    width: '100%',
    margin: '10px 0',
  },
  modalPlusButton: {
    margin: '10px',
    color: theme.palette.secondary.main,
  },
}));

export default useStyles;
