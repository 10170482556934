import React, { useState } from 'react';
import { Search } from '@mui/icons-material';
import PropTypes from 'prop-types';
import { Box, IconButton, InputBase } from '@mui/material';

import useStyles from './styles';

const SearchBox = ({
  setSearch,
  full,
}) => {
  const classes = useStyles();
  const [text, setText] = useState('');

  const handleSearchChange = (event) => {
    setText(event.target.value);
  };

  const handleSearchSubmit = async (event) => {
    event.preventDefault();
    setSearch((prev) => ({
      flag: !prev.flag,
      text,
    }));
  };

  const searchStyle = full ? classes.searchBoxFull : classes.searchBox;
  return (
      <Box
      className={searchStyle}
      component="form" onSubmit={handleSearchSubmit}>
        <InputBase
          className={classes.searchBoxText}
          placeholder="Buscar..."
          inputProps={{ 'aria-label': 'buscar' }}
          value={text}
          onChange={handleSearchChange}
        />
        <IconButton className={classes.searchIcon} type="submit" aria-label="search">
          <Search />
        </IconButton>
      </Box>
  );
};

SearchBox.propTypes = {
  setSearch: PropTypes.func.isRequired,
  full: PropTypes.bool,
};

export default SearchBox;
