import React,
{
  useEffect,
  useState,
} from 'react';

import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import {
  DirectionsRun, DoNotDisturb, Gavel, Pause,
} from '@mui/icons-material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {
  Box,
  Button,
  Grid,
  Pagination,
} from '@mui/material';
import moment from 'moment';
import { format, validate } from 'rut.js';

import ButtonDropDown from '../../components/Custom/ButtonDropDown';
import Blocked from './Blocked';

const Result = ({
  cases,
  casesCount,
  setSelectedCase,
  open,
  setOpen,
  setHide,
  classes,
  page,
  setPage,
  rowsPerPage,
}) => {
  const [selectedRow, setSelectedRow] = useState(null);
  const [totalPages, setTotalPages] = useState(0);
  const [hiddens, setHiddens] = useState({});
  const [showBlocked, setShowBlocked] = useState(false);
  const account = useSelector((state) => state.account);

  const columnWidths = {
    estadoCausaRol: '20%',
    contraparte: '8%',
    rut: '5%',
    tipoCausa: '9%',
    estadoAvance: '10%',
    fecha: '12%',
    comentario: '10%',
    inicioDeCausa: '9%',
    icon: '2%',
  };

  useEffect(() => {
    if (cases?.length) {
      const ids = {};
      for (let i = 0; i < cases.length; i += 1) {
        for (let j = 0; j < cases[i].subCases.length; j += 1) {
          ids[cases[i].subCases[j].id] = true;
          if (cases[i].subCases[j].subCases.length) {
            for (let k = 0; k < cases[i].subCases[j].subCases.length; k += 1) {
              ids[cases[i].subCases[j].subCases[k].id] = true;
            }
          }
        }
      }
      setHiddens(ids);
    }
  }, [cases]);

  useEffect(() => {
    const pages = Math.ceil(casesCount / rowsPerPage);
    setTotalPages(pages);
  }, [casesCount]);

  const handleRowDoubleClick = (c) => {
    setSelectedRow(c);
    setSelectedCase(c);
    setOpen(!open);
    setHide(!open);
  };
  const handleRowClick = (c) => {
    setSelectedRow(c);
    setSelectedCase(c);
  };

  const generateIcon = (option) => {
    switch (option) {
      case 'ACTIVA':
        return <DirectionsRun className={classes.icon} />;
      case 'TERMINADA':
        return <DoNotDisturb className={classes.icon} />;
      case 'FALLADA':
        return <Gavel className={classes.icon} />;
      case 'SUSPENDIDA':
        return <Pause className={classes.icon} />;
      default:
        return <DirectionsRun className={classes.icon} />;
    }
  };

  const generateRows = (cause) => {
    const rowBuilder = (c, indent = 0) => {
      let tableCellClass = classes.tableCell;
      if (selectedRow?.id === c.id) {
        tableCellClass = classes.tableCellSelected;
      }
      const hide = hiddens[c.id];
      return (hide) ? null : (<TableRow key={c.id}
        className={classes.tableRow}
        onClick={() => handleRowClick(c)}
        onDoubleClick={() => handleRowDoubleClick(c)}>
        <TableCell style={{ width: columnWidths.estadoCausaRol, paddingLeft: indent * 16 }} align="left" className={tableCellClass}>
          <Grid spacing={1} container height="100%" alignItems="center" justify="flex-start">
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              {c.subCases?.length ? (<ButtonDropDown onClick={() => {
                const newHiddens = { ...hiddens };
                for (let i = 0; i < c.subCases.length; i += 1) {
                  newHiddens[c.subCases[i].id] = !newHiddens[c.subCases[i].id];
                }
                setHiddens(newHiddens);
              }} />)
                : <div className={classes.spacing} />}
              {generateIcon(c.estadoCausa)}
              {c.rol}
            </Box>
          </Grid>
        </TableCell>
        <TableCell align="center" style={{ width: columnWidths.contraparte }} className={tableCellClass}>
          {c.contraparte}
        </TableCell>
        <TableCell align="center" style={{ width: columnWidths.rut }} className={tableCellClass}>
          {validate(c.rut) ? format(c.rut) : c.rut}
        </TableCell>
        <TableCell align="center" style={{ width: columnWidths.tipoCausa }} className={tableCellClass}>
          {c.tipo}
        </TableCell>
        <TableCell align="center" style={{ width: columnWidths.estadoAvance }} className={tableCellClass}>
          {c.estado}
        </TableCell>
        <TableCell align="center" style={{ width: columnWidths.inicioDeCausa }} className={tableCellClass}>
          {moment(c.inicio).format('DD/MM/YYYY')}
        </TableCell>
        <TableCell align="center" style={{ width: columnWidths.fecha }} className={tableCellClass}>
          {c.fecha === '' ? '' : `${moment(c.fecha).format('DD/MM/YYYY')}`}
        </TableCell>
        <TableCell align="center" style={{ width: columnWidths.comentario }} className={tableCellClass}>
          {c.comentario}
        </TableCell>
        { account?.user?.isAdmin && (<TableCell align="center" style={{ width: columnWidths.icon }} className={tableCellClass}>
            <Button onClick={() => setShowBlocked(true)}>
              <img src='/img/account_circle_off.png'></img>
            </Button>
        </TableCell>) }
      </TableRow>);
    };
    const rows = [];
    const firstRow = rowBuilder(cause);
    rows.push(firstRow);
    if (cause.subCases?.length) {
      cause.subCases.forEach((subCase) => {
        const subRow = rowBuilder(subCase, 1);
        rows.push(subRow);
        if (subCase.subCases?.length) {
          subCase.subCases.forEach((subSubCase) => {
            const subSubRow = rowBuilder(subSubCase, 2);
            rows.push(subSubRow);
          });
        }
      });
    }
    return rows;
  };

  return (
    <>
      <TableContainer sx={{
        mt: 2, pr: 3, borderRadius: 2,
      }}>
        <Table size="small" aria-label="customized table">
          <TableHead>
            <TableRow className={classes.tableRowHeaderDark}>
              <TableCell className={classes.tableCellHeaderDark}
                style={{ width: columnWidths.estadoCausaRol }}>
                Estado de causa / rol / juzgado</TableCell>
              <TableCell
                className={classes.tableCellHeaderDark} style={{ width: columnWidths.contraparte }}>
                Contraparte</TableCell>
              <TableCell
                className={classes.tableCellHeaderDark} style={{ width: columnWidths.rut }}>
                RUT</TableCell>
              <TableCell
                className={classes.tableCellHeaderDark} style={{ width: columnWidths.tipoCausa }}>
                Tipo de causa</TableCell>
              <TableCell
                className={classes.tableCellHeaderDark}
                style={{ width: columnWidths.estadoAvance }}>
                Estado de avance</TableCell>
              <TableCell
                className={classes.tableCellHeaderDark}
                style={{ width: columnWidths.inicioDeCausa }}>
                Fecha de inicio</TableCell>
              <TableCell
                className={classes.tableCellHeaderDark} style={{ width: columnWidths.fecha }}>
                Fecha último movimiento</TableCell>
              <TableCell
                className={classes.tableCellHeaderDark} style={{ width: columnWidths.comentario }}>
                Comentario</TableCell>
              { account?.user?.isAdmin && <TableCell
                className={classes.tableCellHeaderDark} style={{ width: columnWidths.icon }} />}
            </TableRow>
          </TableHead>
          <TableBody>
            {cases?.map((c, i) => generateRows(c, i))}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        sx={{ mt: 2, mb: 2, justifyContent: 'center' }}
        color="primary"
        count={totalPages}
        onChange={(e, v) => setPage(v)}
        page={page}
        size="large"
      />
      <Blocked
        classes={classes}
        open={showBlocked}
        setOpen={setShowBlocked}
        cause={selectedRow}
      />
    </>
  );
};

Result.propTypes = {
  cases: PropTypes.array,
  setSelectedCase: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  setHide: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  casesCount: PropTypes.number.isRequired,
};

export default Result;
