import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import {
  Button,
  Card, CardContent, CardHeader, CircularProgress, Grid, IconButton,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { useSnackbar } from 'notistack';

import { getUserCase, putBlockCase } from '../../../requests/api/cases';
import SearchBox from '../../../components/Custom/SearchBox';

const Blocked = ({
  classes,
  open,
  setOpen,
  cause,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const [loadings, setLoadings] = useState([]);
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [search, setSearch] = useState('');

  const handleClose = () => {
    setUsers([]);
    setOpen(false);
  };

  const getUsers = async () => {
    const data = await getUserCase(cause.id);
    setUsers(data.users);
    setFilteredUsers(data.users);
    setLoadings(data.users.map(() => false));
  };

  useEffect(() => {
    if (open) getUsers();
  }, [open]);

  useEffect(() => {
    if (search) {
      const { text } = search;
      const usrs = users.filter((user) => {
        const { name, lastname, email } = user;
        const fullName = `${name} ${lastname}`;
        return (
          name.toLowerCase().includes(text.toLowerCase())
          || lastname.toLowerCase().includes(text.toLowerCase())
          || email.toLowerCase().includes(text.toLowerCase())
          || fullName.toLowerCase().includes(text.toLowerCase())
        );
      });
      setFilteredUsers(usrs);
    }
  }, [search]);

  const isBlocked = (user) => {
    if (user.blockedCases) {
      const cases = user.blockedCases.split(';');
      return cases.includes(String(cause.id));
    }
    return false;
  };

  const loadingSetter = (i, value) => {
    const newLoadings = [...loadings];
    newLoadings[i] = value;
    setLoadings(newLoadings);
  };

  const manageBlock = async (user, i) => {
    loadingSetter(i, true);
    const { id } = cause;
    try {
      const block = !isBlocked(user);
      await putBlockCase(id, user.id, block);
      const data = await getUserCase(id);
      setUsers(data.users);
      setFilteredUsers(data.users);
    } catch {
      enqueueSnackbar('Error al bloquear la causa', {
        variant: 'error',
      });
    }
    loadingSetter(i, false);
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Card className={classes.floatingCard}>
          <CardHeader
            className={classes.cardHeader}
            action={
              <IconButton onClick={() => setOpen(false)}>
                <Close />
              </IconButton>
            }
            title={
              <Typography variant="h4">BLOQUEO DE CAUSAS | {cause?.rol} </Typography>
            }>
          </CardHeader>
          <CardContent className={classes.cardNoPaddingContent}>
            <div className={classes.caseBlockedRow}>
              <SearchBox setSearch={setSearch} full={true} />
              {filteredUsers?.map((user, i) => (
                <Grid container className={classes.gridContainer} key={user.id}>
                  <Grid item>
                    <p>
                    <span>{user?.name} {user?.lastname}</span> | {user?.email}
                    </p>
                  </Grid>
                  <Grid item>
                  { loadings[i] ? (<CircularProgress />)
                    : (<Button onClick={async () => {
                      await manageBlock(user, i);
                    }}>
                    { isBlocked(user) ? <img src='/img/visibility_off.png'/>
                      : (<img src='/img/visibility.png'/>)}
                  </Button>)}
                  </Grid>
                </Grid>
              ))}
            </div>
          </CardContent>
        </Card>
      </Modal>
    </div>
  );
};

Blocked.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  classes: PropTypes.object,
  cause: PropTypes.object,
};

export default Blocked;
