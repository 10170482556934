import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  headerBss: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

export default useStyles;
