import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
} from '@mui/material';

const HeaderButton = ({
  onClick,
  icon,
  text,
  disabled = false,
  component = '',
}) => (
    <Button
        variant="contained"
        onClick={onClick}
        sx={{
          backgroundColor: '#222A35',
          color: 'white',
          width: '250px',
          height: '50px',
          borderRadius: '0px',
          margin: '1px',
        }}
        component={component}
        disabled={disabled}
    >
      <Box style={{ marginRight: '3%', display: 'flex', alignItems: 'center' }}>
      {icon}
      </Box>
      {text}
    </Button>
);

HeaderButton.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func,
  icon: PropTypes.node,
  disabled: PropTypes.bool,
  component: PropTypes.string,
};

export default HeaderButton;
