import { getCases, getClientCases, getCasesByKey } from '../requests/api/cases';

const getAllCases = async ({
  clientId,
  start,
  end,
  tribunal,
  status,
  jurisdiccion,
  limit,
  page,
}) => {
  try {
    const response = await getCases({
      clientId, start, end, tribunal, status, limit, page, jurisdiccion,
    });
    return {
      cases: response.cases || [],
      count: response.totalCases,
    };
  } catch {
    return {
      cases: null,
      count: 0,
      message: 'Error al obtener las causas',
    };
  }
};

const getCaseByKey = async (search, limit, page, clientId) => {
  try {
    const response = await getCasesByKey(search, limit, page, clientId);
    return {
      cases: response.cases || [],
      count: response.totalCases,
    };
  } catch {
    return {
      cases: null,
      count: 0,
      message: 'Error al obtener las causas',
    };
  }
};

const getAllClients = async (ids) => {
  try {
    const response = await getClientCases(ids);
    return {
      clients: response.clients || [],
      message: 'Clientes obtenidos correctamente',
    };
  } catch {
    return {
      clients: [],
      message: 'Error al obtener los clientes',
    };
  }
};

export {
  getAllCases,
  getCaseByKey,
  getAllClients,
};
