import axios from '../../utils/axios';

const getDocumentBlob = async (id) => {
  try {
    const response = await axios.get(`/api/cases/docs/${id}`);
    return response.data?.document;
  } catch (err) {
    return null;
  }
};

const getBillingBlob = async (id) => {
  try {
    const response = await axios.get(`/api/billings/docs/${id}`);
    return response.data?.document;
  } catch (err) {
    return null;
  }
};

export {
  // eslint-disable-next-line import/prefer-default-export
  getDocumentBlob,
  getBillingBlob,
};
