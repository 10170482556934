import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router';
import {
  SvgIcon,
  CircularProgress,
  Box,
  Tabs,
  Tab,
  Typography,
  Select,
  MenuItem,
  TextField,
  Button,
  Grid,
  Divider,
} from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import {
  PlusCircle as PlusCircleIcon,
  Download as DownloadIcon,
  Upload as UploadIcon,
  Filter as FilterIcon,
  Trash2 as TrashIcon,
  Plus as PlusIcon,
} from 'react-feather';

import useStyles from './styles';
import CustomHeader from '../../../components/Layout/ CustomHeader';
import HeaderButton from '../../../components/Layout/HeaderButton';
import {
  CLIENT_VIEW,
  NOTIFICATIONS_VIEW,
  PERSONA_JURIDICA,
  PERSONA_NATURAL,
} from '../../../utils/const';
import SearchBox from '../../../components/Custom/SearchBox';
import FloatingWindow from '../../../components/Custom/FloatWindow';
import Popup from '../../../components/Custom/Popup';

const sortOptions = [
  {
    value: 'fec_ingreso|desc',
    label: 'Más nuevo primero',
  },
  {
    value: 'fec_ingreso|asc',
    label: 'Más antiguo primero',
  },
];

const personTypeOptions = [
  {
    label: 'Jurídica',
    value: PERSONA_JURIDICA,
  },
  {
    label: 'Natural',
    value: PERSONA_NATURAL,
  },
];

function Header({
  setView,
  className,
  setFilters,
  setQuery,
  sort,
  setSort,
  uploadFile,
  downloadFile,
  downloadDefaultFile,
  newClients,
  setNewClients,
  addNewClient,
  ...rest
}) {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  const { pathname } = location || {};

  const [open, setOpen] = useState(false);
  const [openAddClient, setOpenAddClient] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [isImportLoading, setIsImportLoading] = useState(false);
  const [isExportLoading, setIsExportLoading] = useState(false);
  const [addClientLoading, setAddClientLoading] = useState(false);

  const plusIcon = () => (<SvgIcon
    fontSize="small"
    className={classes.actionIcon}
  >
    <PlusCircleIcon />
  </SvgIcon>);

  const filterIcon = () => (<SvgIcon
    fontSize="small"
    className={classes.actionIcon}
  >
    <FilterIcon />
  </SvgIcon>);

  const uploadIcon = () => {
    if (isImportLoading) return (<CircularProgress sx={{ color: 'white' }} size={22} />);
    return (<SvgIcon
      fontSize="small"
      className={classes.actionIcon}
    >
      <UploadIcon />
    </SvgIcon>);
  };

  const downloadIcon = () => {
    if (isExportLoading) return (<CircularProgress sx={{ color: 'white' }} size={22} />);
    return (<SvgIcon
      fontSize="small"
      className={classes.actionIcon}
    >
      <DownloadIcon />
    </SvgIcon>);
  };

  const openFilters = () => setOpen(!open);

  const closeFilters = () => setOpen(false);

  const onFileChange = async (event) => {
    const file = event.target.files[0];
    setIsImportLoading(true);
    await uploadFile(file);
    setIsImportLoading(false);

    // Reset input file
    const fileInput = document.getElementById('upload-file-client-input');
    fileInput.disabled = true;
    fileInput.value = '';
    fileInput.disabled = false;
  };

  const handleExportClients = async () => {
    setIsExportLoading(true);
    await downloadFile();
    setIsExportLoading(false);
  };

  const handleAddNewClientRow = () => {
    const newRow = {
      rut: '',
      tipoPersona: PERSONA_JURIDICA,
      nombre: '',
      paterno: '',
      materno: '',
    };
    setNewClients((prev) => [...prev, newRow]);
  };

  const handleRemoveNewClientRow = (index) => {
    const filteredClientRows = newClients.filter((c, i) => i !== index);
    setNewClients(filteredClientRows);
  };

  const handleNewClientChange = (event, index) => {
    const { name, value } = event.target;
    const newClientsCopy = [...newClients];
    newClientsCopy[index][name] = value;
    setNewClients(newClientsCopy);
  };

  const handelCancelNewClient = () => {
    setNewClients([{
      rut: '',
      tipoPersona: PERSONA_JURIDICA,
      nombre: '',
      paterno: '',
      materno: '',
    }]);
    setOpenAddClient(false);
  };

  const handelCreateNewClient = async () => {
    setAddClientLoading(true);
    const { success } = await addNewClient();
    setAddClientLoading(false);
    if (success) {
      setOpenAddClient(false);
      setNewClients([{
        rut: '',
        tipoPersona: PERSONA_JURIDICA,
        nombre: '',
        paterno: '',
        materno: '',
      }]);
    }
  };

  return (
    <>
      <CustomHeader
        classes={classes}
        className={className}
        title="Boletín BSS"
        {...rest}
      >
        <Tabs value={pathname}>
          <Tab
            sx={{
              width: '360px',
              backgroundColor: pathname === CLIENT_VIEW ? '#FCC561' : '#222A35',
            }}
            variant="fullWidth"
            value={CLIENT_VIEW}
            label="Clientes"
            onClick={() => {
              history.push('/quiebras/clientes');
            }}
          />
          <Tab
            sx={{
              width: '400px',
              backgroundColor: pathname === NOTIFICATIONS_VIEW ? '#FCC561' : '#222A35',
            }}
            variant="fullWidth"
            value={NOTIFICATIONS_VIEW}
            label="Notificaciones"
            onClick={() => {
              history.push('/quiebras/notificaciones');
            }}
          />
        </Tabs>
      </CustomHeader >
      <Box
        className={classes.subButtons}
      >
        <SearchBox
          setSearch={setQuery}
        />
        <HeaderButton
          icon={filterIcon()}
          onClick={openFilters}
          text="Filtrar clientes"
        />
        <HeaderButton
          icon={plusIcon()}
          onClick={() => setOpenAddClient(true)}
          text="Agregar cliente"
        />
        <label htmlFor="upload-file-client-input">
          <input
            style={{ display: 'none' }}
            id="upload-file-client-input"
            name="upload-file"
            type="file"
            onChange={onFileChange}
          />
          <HeaderButton
            icon={uploadIcon()}
            text="Subir Excel"
            disabled={isImportLoading}
            component="span"
          />
        </label>
        <HeaderButton
          icon={downloadIcon()}
          onClick={downloadDefaultFile}
          text="Plantilla de Carga"
          disabled={isExportLoading}
        />
        <HeaderButton
          icon={downloadIcon()}
          onClick={handleExportClients}
          text="Descargar Clientes"
          disabled={isExportLoading}
        />
      </Box>
      <FloatingWindow
        open={open}
        setOpen={setOpen}
        transform='translate(40% , 0%)'
      >
        <Box className={{ ...classes.boxFilter, minWidth: '500px' }}>
          <Typography className={classes.filterTitle} variant="subtitle1">
            Rango de fechas
          </Typography>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Box sx={{ alignItems: 'center' }} className={classes.boxRow}>
              <DesktopDatePicker
                className={classes.datePickerLeft}
                label="Fecha inicio"
                inputFormat="DD/MM/YYYY"
                value={startDate}
                onChange={(date) => {
                  setStartDate(date?.toDate());
                }}
                renderInput={(params) => <TextField {...params} />}
              />
              <div> - </div>
              <DesktopDatePicker
                className={classes.datePickerRight}
                label="Fecha termino"
                inputFormat="DD/MM/YYYY"
                value={endDate}
                onChange={(date) => {
                  setEndDate(date?.toDate());
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </Box>
          </LocalizationProvider>
          <Typography className={classes.filterTitle} variant="subtitle1">Orden</Typography>
          <Select
            value={sort}
            className={classes.filterSelect}
            onChange={(event) => setSort(event.target.value)}
          >
            {sortOptions?.map((option, i) => (
              <MenuItem key={i} value={option.value}>{option.label}</MenuItem>
            ))}
          </Select>
          <Box className={classes.buttonBox}>
            <Button className={classes.button}
              onClick={() => {
                setFilters({
                  sort: sortOptions[0].value,
                  startDate: null,
                  endDate: null,
                });
                closeFilters();
                setStartDate(null);
                setEndDate(null);
              }}>
              DESHACER FILTROS
            </Button>
            <Button className={classes.yellowButton}
              onClick={() => {
                setQuery('');
                closeFilters();
                setFilters({
                  sort,
                  startDate,
                  endDate,
                });
              }}>
              APLICAR FILTROS
            </Button>
          </Box>
        </Box>
      </FloatingWindow>
      <Popup
        open={openAddClient}
        onClose={() => setOpenAddClient(false)}
        title="AGREGAR CLIENTE"
        maxWidth="1200px"
      >
        <Grid container>
          <Grid
            item
            xs={12}
            maxHeight={520}
            style={{ overflowY: newClients.length > 1 ? 'scroll' : 'unset' }}
          >
            {newClients.map((newClient, index) => (
              <Box key={`new-client-key-${index}`}>
                <Grid container padding="20px" spacing={1} columns={16}>
                  <Grid item xs={12} md={6} lg={3} textAlign="center">
                    <TextField
                      id="bankrupts-person-type-select"
                      select
                      className={classes.modalTextField}
                      style={{ textAlign: 'start' }}
                      name="tipoPersona"
                      variant="outlined"
                      defaultValue={personTypeOptions[0].value}
                      onChange={(e) => handleNewClientChange(e, index)}
                    >
                      {personTypeOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12} md={6} lg={3} textAlign="center">
                    <TextField
                      className={classes.modalTextField}
                      onChange={(e) => handleNewClientChange(e, index)}
                      label="Rut a Consultar"
                      placeholder="Rut a Consultar"
                      name="rut"
                      value={newClient.rut}
                      variant="outlined"
                    />
                  </Grid>
                  {newClient.tipoPersona === PERSONA_JURIDICA ? (
                    <>
                      <Grid item xs={12} md={6} lg={3} textAlign="center">
                        <TextField
                          className={classes.modalTextField}
                          onChange={(e) => handleNewClientChange(e, index)}
                          label="Razón Social"
                          placeholder="Razón Social"
                          name="nombre"
                          value={newClient.nombre}
                          variant="outlined"
                        />
                      </Grid>
                      {newClients.length > 1 ? (
                        <Grid item xs={1} display="flex" justifyContent="center">
                          <Button
                            onClick={() => handleRemoveNewClientRow(index)}
                          >
                            <SvgIcon><TrashIcon /></SvgIcon>
                          </Button>
                        </Grid>
                      ) : null}
                    </>

                  ) : (
                    <>
                      <Grid item xs={12} md={6} lg={3} textAlign="center">
                        <TextField
                          className={classes.modalTextField}
                          onChange={(e) => handleNewClientChange(e, index)}
                          label="Nombre"
                          placeholder="Nombre"
                          name="nombre"
                          value={newClient.nombre}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} md={6} lg={3} textAlign="center">
                        <TextField
                          className={classes.modalTextField}
                          onChange={(e) => handleNewClientChange(e, index)}
                          label="Paterno"
                          placeholder="Paterno"
                          name="paterno"
                          value={newClient.paterno}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} md={6} lg={3} textAlign="center">
                        <TextField
                          className={classes.modalTextField}
                          onChange={(e) => handleNewClientChange(e, index)}
                          label="Materno"
                          placeholder="Materno"
                          name="materno"
                          value={newClient.materno}
                          variant="outlined"
                        />
                      </Grid>
                      {newClients.length > 1 ? (
                        <Grid item xs={1} display="flex" justifyContent="center">
                          <Button
                            onClick={() => handleRemoveNewClientRow(index)}
                          >
                            <SvgIcon><TrashIcon /></SvgIcon>
                          </Button>
                        </Grid>
                      ) : null}
                    </>
                  )}
                </Grid>
                <Grid item xs={16} textAlign="center">
                  <Divider sx={{ borderBottomWidth: 3, borderColor: '#FCC561' }} />
                </Grid>
              </Box>
            ))}
            {newClients.length < 10 ? (
              <Grid item xs={12} textAlign="center">
                <Button
                  className={classes.modalPlusButton}
                  onClick={handleAddNewClientRow}
                >
                  <SvgIcon><PlusIcon /></SvgIcon>
                </Button>
              </Grid>
            ) : null}
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12} md={6}>
                <Button
                  className={classes.modalCancelButton}
                  onClick={handelCancelNewClient}
                  disabled={addClientLoading}
                >
                  CANCELAR
                </Button>
              </Grid>
              <Grid item xs={12} md={6}>
                <Button
                  className={classes.modalAddButton}
                  onClick={handelCreateNewClient}
                  disabled={addClientLoading}
                >
                  AGREGAR
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Popup>
    </>
  );
}

Header.propTypes = {
  view: PropTypes.string,
  setView: PropTypes.func,
  className: PropTypes.object,
  setFilters: PropTypes.func,
  sort: PropTypes.string,
  setSort: PropTypes.func,
  setQuery: PropTypes.func,
  isImportLoading: PropTypes.bool,
  setIsImportLoading: PropTypes.func,
  uploadFile: PropTypes.func,
  isExportLoading: PropTypes.bool,
  setIsExportLoading: PropTypes.func,
  downloadFile: PropTypes.func,
  downloadDefaultFile: PropTypes.func,
  newClients: PropTypes.array,
  setNewClients: PropTypes.func,
  addNewClient: PropTypes.func,
};

export default Header;
